import React, { Component } from "react";
import LoginForm from "../../components/form/LoginForm";

class Login extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isAlertActive: false,
      alertMsg: ""
    };
    this._onError = this._onError.bind(this);
    this._onAlertClick = this._onAlertClick.bind(this);
  }

  _onError(err) {
    console.log(err);
    let alertMsg =
      "There was an issue logging in. Please double-check your e-mail and password.";
    if (
      err instanceof XMLHttpRequest &&
      err.responseText !== undefined &&
      err.responseText !== null &&
      err.responseText !== ""
    ) {
      let errorMsg = JSON.parse(err.responseText);
      alertMsg = errorMsg.description;
    }
    this.setState({
      isAlertActive: true,
      alertMsg: alertMsg
    });
  }

  _onAlertClick(event) {
    event.preventDefault();
    this.setState({
      isAlertActive: false
    });
  }
  render() {
    const alertClass = `alert alert-danger alert-dismissible fade show${
      this.state.isAlertActive ? "" : " d-none"
    }`;
    return (
      <div className="container-fluid">
        <div className="row justify-content-center pt-5">
          <div className="col-3 text-center align-self-center">
            <img className="img-fluid" src="images/logo.svg" alt="" />
          </div>

          <div className="w-100" />

          <div className="col-6 align-self-center">
            <div className={alertClass} role="alert">
              {this.state.alertMsg}
              <button
                type="button"
                className="close"
                onClick={this._onAlertClick}
              >
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
          </div>

          <div className="w-100" />

          <div className="col-6 align-self-center">
            <LoginForm
              onError={this._onError}
              history={this.props.history}
              location={this.props.location}
            />
          </div>
        </div>
      </div>
    );
  }
}

export default Login;
