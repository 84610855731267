import ApiResource from "./ApiResource";

export default class AuthResource extends ApiResource {
  static async login(credentials) {
    return await ApiResource._request(
      "POST",
      "/login",
      null,
      `${credentials.email}:${credentials.password}`
    );
  }

  static async register(credentials) {
    return await ApiResource._request(
      "POST",
      "/register",
      JSON.stringify(credentials)
    );
  }
}
