import React, { Component } from "react";
import { Link } from "react-router-dom";

class Home extends Component {
  render() {
    return (
      <div className="container-fluid">
        <dl className="row pt-5">
          <dt className="col-3 offset-3">
            <Link to={"/data/timeseries"}>Time Series [Hero]</Link>
          </dt>
          <dd className="col-6">
            Analiza las métricas del héroe a lo largo del tiempo
          </dd>

          <dt className="col-3 offset-3">
            <Link to={"/draft"}>Draft</Link>
          </dt>
          <dd className="col-6">Evaluar borrador</dd>

          <dt className="col-3 offset-3">
            <Link to={"/draft/match"}>Draft [Match]</Link>
          </dt>
          <dd className="col-6">Evaluar borradores para un partido</dd>

          <dt className="col-3 offset-3">
            <Link to={"/draft/matchup"}>Draft Counter Pick</Link>
          </dt>
          <dd className="col-6">
            Evaluar las selecciones de contador para un proyecto [EXPERIMENTAL]
          </dd>

          <dt className="col-3 offset-3">
            <Link to={"/draft/lineup"}>Draft Lineup</Link>
          </dt>
          <dd className="col-6">
            Evaluar los borradores por grupos de héroe por posición
          </dd>

          <dt className="col-3 offset-3">
            <Link to={"/draft/lineup/parcat"}>Draft Lineup Parcat</Link>
          </dt>
          <dd className="col-6">
            Explora combinaciones de alineaciones por posición
          </dd>

          <dt className="col-3 offset-3">
            <Link to={"/draft/lineup/rollup"}>Draft Lineup Rollup</Link>
          </dt>
          <dd className="col-6">
            Explora combinaciones de alineaciones por posición
          </dd>

          <dt className="col-3 offset-3">
            <Link to={"/draft/lineup/tree"}>Draft Lineup Tree</Link>
          </dt>
          <dd className="col-6">
            Explora combinaciones de alineaciones por posición
          </dd>

          <dt className="col-3 offset-3">
            <Link to={"/draft/lastpick"}>Draft Last Pick</Link>
          </dt>
          <dd className="col-6">
            Averiguar una última selección [EXPERIMENTAL]
          </dd>

          <dt className="col-3 offset-3">
            <Link to={"/draft/pregen"}>Draft Pre-Generation</Link>
          </dt>
          <dd className="col-6">
            Seleccionar héroes para rellenar previamente los borradores en las
            actualizaciones
          </dd>
        </dl>
      </div>
    );
  }
}

export default Home;
