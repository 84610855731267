import React from "react";
import { BrowserRouter, Redirect, Route, Switch } from "react-router-dom";
import Home from "./routes/home/Home";
import Login from "./routes/login/Login";
import Timeseries from "./routes/data/Timeseries";
import Draft from "./routes/draft/Draft";
import DraftMatch from "./routes/draft/DraftMatch";
import DraftLastPick from "./routes/draft/DraftLastPick";
import DraftLineup from "./routes/draft/DraftLineup";
import DraftLineupParcat from "./routes/draft/DraftLineupParcat";
import DraftLineupRollup from "./routes/draft/DraftLineupRollup";
import DraftLineupTree from "./routes/draft/DraftLineupTree";
import DraftMatchup from "./routes/draft/DraftMatchup";
import DraftPregen from "./routes/draft/DraftPregen";
import AuthStorage from "./storage/AuthStorage";

const AUTH_SESSION = new AuthStorage();

const AuthRoute = ({ component, ...rest }) => (
  <Route
    {...rest}
    render={props =>
      AUTH_SESSION.isAuthenticated ? (
        React.createElement(component, props)
      ) : (
        <Redirect
          to={{ pathname: "/login", state: { from: props.location } }}
        />
      )
    }
  />
);

const AnonRoute = ({ component, ...rest }) => (
  <Route
    {...rest}
    render={props =>
      !AUTH_SESSION.isAuthenticated ? (
        React.createElement(component, props)
      ) : (
        <Redirect to={{ pathname: "/", state: { from: props.location } }} />
      )
    }
  />
);

export default class Router extends React.Component {
  render() {
    return (
      <BrowserRouter>
        <Switch>
          <AuthRoute exact path={"/"} component={Home} />
          <AnonRoute exact path="/login" component={Login} />
          <AuthRoute exact path={"/data/timeseries"} component={Timeseries} />
          <AuthRoute exact path={"/draft"} component={Draft} />
          <AuthRoute exact path={"/draft/match"} component={DraftMatch} />
          <AuthRoute exact path={"/draft/lastpick"} component={DraftLastPick} />
          <AuthRoute exact path={"/draft/lineup"} component={DraftLineup} />
          <AuthRoute
            exact
            path={"/draft/lineup/parcat"}
            component={DraftLineupParcat}
          />
          <AuthRoute
            exact
            path={"/draft/lineup/rollup"}
            component={DraftLineupRollup}
          />
          <AuthRoute
            exact
            path={"/draft/lineup/tree"}
            component={DraftLineupTree}
          />
          <AuthRoute exact path={"/draft/matchup"} component={DraftMatchup} />
          <AuthRoute exact path={"/draft/pregen"} component={DraftPregen} />
        </Switch>
      </BrowserRouter>
    );
  }
}
