import hero_metadata from "../assets/hero_metadata";
import hero_tags from "../assets/hero_tags";

const zpad = (n, width, z) => {
  z = z || "0";
  n = n + "";
  return n.length >= width ? n : new Array(width - n.length + 1).join(z) + n;
};

const k_combinations = (set, k) => {
  let i, j, combs, head, tailcombs;

  if (k > set.length || k <= 0) {
    return [];
  }

  if (k === set.length) {
    return [set];
  }

  if (k === 1) {
    combs = [];
    for (i = 0; i < set.length; i++) {
      combs.push([set[i]]);
    }
    return combs;
  }
  combs = [];
  for (i = 0; i < set.length - k + 1; i++) {
    head = set.slice(i, i + 1);
    tailcombs = k_combinations(set.slice(i + 1), k - 1);
    for (j = 0; j < tailcombs.length; j++) {
      combs.push(head.concat(tailcombs[j]));
    }
  }
  return combs;
};

const HERO_SELECT_OPTIONS = hero_metadata.map(hero => {
  return {
    value: hero["id"],
    label: hero["name"],
    icon: `/images/heroes/h/${zpad(hero["id"], 3)}.png`
  };
});

const HERO_ID_TO_PROPS = {};
hero_metadata.forEach(hero => {
  HERO_ID_TO_PROPS[hero["id"]] = {
    name: hero["name"],
    icon: `/images/heroes/h/${zpad(hero["id"], 3)}.png`
  };
});

const HERO_NAME_TO_ID = {};
hero_metadata.forEach(hero => {
  HERO_NAME_TO_ID[hero["name"]] = hero["id"];
});

const POSITION_SELECT_OPTIONS = [
  {
    value: "0",
    label: "Carry"
  },
  {
    value: "1",
    label: "Mid"
  },
  {
    value: "2",
    label: "Offlane"
  },
  {
    value: "3",
    label: "Support (4)"
  },
  {
    value: "4",
    label: "Support (5)"
  }
];

const HERO_TAG_OPTIONS = hero_tags.map(tags => {
  return {
    value: tags,
    label: tags
  };
});

const getPositionsFromKey = params => {
  switch (params) {
    case "h0":
      return "Carry";
    case "h1":
      return "Mid";
    case "h2":
      return "Offlane";
    case "h3":
      return "Support [4]";
    case "h4":
      return "Support [5]";
  }
};

const getHeroIdsFromQueryParams = params => {
  if (typeof params === "string") {
    return [parseInt(params)];
  } else if (params.constructor === Array) {
    return params.map(p => {
      return parseInt(p);
    });
  }
};

const getHeroSelectFromQueryParams = params => {
  if (typeof params === "string") {
    const id = parseInt(params);
    return HERO_SELECT_OPTIONS.filter(h => {
      return h.value === id;
    });
  } else if (params.constructor === Array) {
    return params.map(p => {
      const id = parseInt(p);
      return HERO_SELECT_OPTIONS.filter(h => {
        return h.value === id;
      })[0];
    });
  }
};

const getTagsFromQueryParams = params => {
  let tags = {};
  ["t1", "t2", "t3", "t4", "t5", "tt"]
    .filter(t => {
      return t in params;
    })
    .forEach(t => {
      const tag = params[t];

      if (typeof tag === "string") {
        tags[t] = [tag];
      } else if (tag.constructor === Array) {
        tags[t] = tag;
      }
    });
  return tags;
};

const getTagSelectFromQueryParams = params => {
  if (typeof params === "string") {
    return HERO_TAG_OPTIONS.filter(h => {
      return h.value === params;
    });
  } else if (params.constructor === Array) {
    return params.map(p => {
      return HERO_TAG_OPTIONS.filter(h => {
        return h.value === p;
      })[0];
    });
  }
};

export {
  HERO_ID_TO_PROPS,
  HERO_NAME_TO_ID,
  HERO_SELECT_OPTIONS,
  HERO_TAG_OPTIONS,
  POSITION_SELECT_OPTIONS,
  zpad,
  k_combinations,
  getPositionsFromKey,
  getHeroIdsFromQueryParams,
  getHeroSelectFromQueryParams,
  getTagsFromQueryParams,
  getTagSelectFromQueryParams
};
