import React, { Component } from "react";
import Select from "react-select";
import ReactTable from "react-table";
import "react-table/react-table.css";
import Slider from "rc-slider";
import "rc-slider/assets/index.css";
import * as queryString from "query-string";
import { debounce } from "debounce";
import hero_metadata from "../../assets/hero_metadata";
import DraftResource from "../../resources/DraftResource";
import JobResource from "../../resources/JobResource";
import {
  getHeroIdsFromQueryParams,
  getHeroSelectFromQueryParams,
  getTagSelectFromQueryParams,
  getTagsFromQueryParams,
  HERO_NAME_TO_ID,
  HERO_SELECT_OPTIONS,
  HERO_TAG_OPTIONS
} from "../../utils/HeroUtils";
import { filterHeroCell, formatHeroCell } from "../../utils/TableUtils";
import { formatOptionLabel } from "../../utils/SelectUtils";
import MatchUpTable from "../../components/MatchUpTable";
import WinProbByDurationGraph from "../../components/WinProbByDurationGraph";

const COLUMNS = [
  {
    Header: "Carry",
    accessor: "draft.h0",
    Cell: formatHeroCell,
    filterable: true,
    filterMethod: filterHeroCell,
    sortable: false
  },
  {
    Header: "Mid",
    accessor: "draft.h1",
    Cell: formatHeroCell,
    filterable: true,
    filterMethod: filterHeroCell,
    sortable: false
  },
  {
    Header: "Offlane",
    accessor: "draft.h2",
    Cell: formatHeroCell,
    filterable: true,
    filterMethod: filterHeroCell,
    sortable: false
  },
  {
    Header: "Support (4)",
    accessor: "draft.h3",
    Cell: formatHeroCell,
    filterable: true,
    filterMethod: filterHeroCell,
    sortable: false
  },
  {
    Header: "Support(5)",
    accessor: "draft.h4",
    Cell: formatHeroCell,
    filterable: true,
    filterMethod: filterHeroCell,
    sortable: false
  },
  {
    Header: "Win %",
    accessor: "win_probability.blended"
  },
  {
    Header: "Comfort Score",
    accessor: "comfort_score"
  },
  {
    Header: `Q1 Win %`,
    accessor: "win_probability.q1",
    sortable: false
  },
  {
    Header: `Median Win`,
    accessor: "win_probability.q2",
    sortable: false
  },
  {
    Header: `Q3 Win %`,
    accessor: "win_probability.q3",
    sortable: false
  }
];

const renderMatchups = row => {
  return <DraftLineupSubComponent row={row} />;
};

const SliderWithToolTip = Slider.createSliderWithTooltip(Slider);

class DraftLineupSubComponent extends Component {
  constructor(props) {
    super(props);
    this.state = {
      data: null
    };
  }

  async componentDidMount() {
    const data = await DraftResource.getDraft(
      this.props.row.original.draft.h0,
      this.props.row.original.draft.h1,
      this.props.row.original.draft.h2,
      this.props.row.original.draft.h3,
      this.props.row.original.draft.h4
    );
    this.setState({
      data: data
    });
  }

  render() {
    if (this.state.data === null) {
      return null;
    }

    const matchups = hero_metadata.map(hero => {
        return {
          id: hero["id"],
          win_probability: this.state.data.matchups[hero["id"]]
        };
      }),
      duration_win_probability = this.state.data.duration_win_probability;
    return (
      <div>
        <WinProbByDurationGraph
          duration_win_probability={duration_win_probability}
        />

        <MatchUpTable matchups={matchups} />
      </div>
    );
  }
}

class DraftLineup extends Component {
  constructor(props) {
    super(props);
    const qs = queryString.parse(this.props.location.search);
    this.state = {
      h1: "1" in qs ? getHeroIdsFromQueryParams(qs["1"]) : [],
      h2: "2" in qs ? getHeroIdsFromQueryParams(qs["2"]) : [],
      h3: "3" in qs ? getHeroIdsFromQueryParams(qs["3"]) : [],
      h4: "4" in qs ? getHeroIdsFromQueryParams(qs["4"]) : [],
      h5: "5" in qs ? getHeroIdsFromQueryParams(qs["5"]) : [],
      defaultH1: "1" in qs ? getHeroSelectFromQueryParams(qs["1"]) : [],
      defaultH2: "2" in qs ? getHeroSelectFromQueryParams(qs["2"]) : [],
      defaultH3: "3" in qs ? getHeroSelectFromQueryParams(qs["3"]) : [],
      defaultH4: "4" in qs ? getHeroSelectFromQueryParams(qs["4"]) : [],
      defaultH5: "5" in qs ? getHeroSelectFromQueryParams(qs["5"]) : [],
      hthreshold: 0.7,
      csthreshold: 5.0,
      tags: getTagsFromQueryParams(qs),
      defaultT1: "t1" in qs ? getTagSelectFromQueryParams(qs["t1"]) : [],
      defaultT2: "t2" in qs ? getTagSelectFromQueryParams(qs["t2"]) : [],
      defaultT3: "t3" in qs ? getTagSelectFromQueryParams(qs["t3"]) : [],
      defaultT4: "t4" in qs ? getTagSelectFromQueryParams(qs["t4"]) : [],
      defaultT5: "t5" in qs ? getTagSelectFromQueryParams(qs["t5"]) : [],
      defaultTT: "tt" in qs ? getTagSelectFromQueryParams(qs["tt"]) : [],
      job: null,
      jobInterval: null,
      isSubmitDisabled: !(
        "1" in qs &&
        "2" in qs &&
        "3" in qs &&
        "4" in qs &&
        "5" in qs
      ),
      isLineupEnabled: false,
      lineup: [],
      lineupPages: null,
      isLineupLoading: false
    };
    this._onSelectH1 = this._onSelectH1.bind(this);
    this._onSelectH2 = this._onSelectH2.bind(this);
    this._onSelectH3 = this._onSelectH3.bind(this);
    this._onSelectH4 = this._onSelectH4.bind(this);
    this._onSelectH5 = this._onSelectH5.bind(this);
    this._onSelectT1 = this._onSelectT1.bind(this);
    this._onSelectT2 = this._onSelectT2.bind(this);
    this._onSelectT3 = this._onSelectT3.bind(this);
    this._onSelectT4 = this._onSelectT4.bind(this);
    this._onSelectT5 = this._onSelectT5.bind(this);
    this._onSelectTT = this._onSelectTT.bind(this);
    this._onSetHthreshold = this._onSetHthreshold.bind(this);
    this._onSetCsthreshold = this._onSetCsthreshold.bind(this);
    this._onSubmit = this._onSubmit.bind(this);
    this._onQuery = this._onQuery.bind(this);
    this.fetchLineup = this.fetchLineup.bind(this);
    this.updateQueryParams = this.updateQueryParams.bind(this);
  }

  static __isSubmitDisabled(h1, h2, h3, h4, h5) {
    return ![h1, h2, h3, h4, h5].every(h => {
      return h.length > 0;
    });
  }

  _onSelectH1(options) {
    const value = options.map(o => {
      return o.value;
    });
    this.setState({
      h1: value,
      isSubmitDisabled: DraftLineup.__isSubmitDisabled(
        options,
        this.state.h2,
        this.state.h3,
        this.state.h4,
        this.state.h5
      )
    });
    this.updateQueryParams(
      value,
      this.state.h2,
      this.state.h3,
      this.state.h4,
      this.state.h5,
      this.state.tags
    );
  }

  _onSelectH2(options) {
    const value = options.map(o => {
      return o.value;
    });
    this.setState({
      h2: value,
      isSubmitDisabled: DraftLineup.__isSubmitDisabled(
        this.state.h1,
        options,
        this.state.h3,
        this.state.h4,
        this.state.h5
      )
    });
    this.updateQueryParams(
      this.state.h1,
      value,
      this.state.h3,
      this.state.h4,
      this.state.h5,
      this.state.tags
    );
  }

  _onSelectH3(options) {
    const value = options.map(o => {
      return o.value;
    });
    this.setState({
      h3: value,
      isSubmitDisabled: DraftLineup.__isSubmitDisabled(
        this.state.h1,
        this.state.h2,
        options,
        this.state.h4,
        this.state.h5
      )
    });
    this.updateQueryParams(
      this.state.h1,
      this.state.h2,
      value,
      this.state.h4,
      this.state.h5,
      this.state.tags
    );
  }

  _onSelectH4(options) {
    const value = options.map(o => {
      return o.value;
    });
    this.setState({
      h4: value,
      isSubmitDisabled: DraftLineup.__isSubmitDisabled(
        this.state.h1,
        this.state.h2,
        this.state.h3,
        options,
        this.state.h5
      )
    });
    this.updateQueryParams(
      this.state.h1,
      this.state.h2,
      this.state.h3,
      value,
      this.state.h5,
      this.state.tags
    );
  }

  _onSelectH5(options) {
    const value = options.map(o => {
      return o.value;
    });
    this.setState({
      h5: value,
      isSubmitDisabled: DraftLineup.__isSubmitDisabled(
        this.state.h1,
        this.state.h2,
        this.state.h3,
        this.state.h4,
        options
      )
    });
    this.updateQueryParams(
      this.state.h1,
      this.state.h2,
      this.state.h3,
      this.state.h4,
      value,
      this.state.tags
    );
  }

  _onSelectT1(options) {
    const value = options.map(o => {
      return o.value;
    });
    let tags = this.state.tags;
    if (value.length > 0) {
      tags["t1"] = value;
    } else {
      delete tags["t1"];
    }
    this.setState({
      tags: tags
    });
    this.updateQueryParams(
      this.state.h1,
      this.state.h2,
      this.state.h3,
      this.state.h4,
      this.state.h5,
      tags
    );
  }

  _onSelectT2(options) {
    const value = options.map(o => {
      return o.value;
    });
    let tags = this.state.tags;
    if (value.length > 0) {
      tags["t2"] = value;
    } else {
      delete tags["t2"];
    }
    this.setState({
      tags: tags
    });
    this.updateQueryParams(
      this.state.h1,
      this.state.h2,
      this.state.h3,
      this.state.h4,
      this.state.h5,
      tags
    );
  }

  _onSelectT3(options) {
    const value = options.map(o => {
      return o.value;
    });
    let tags = this.state.tags;
    if (value.length > 0) {
      tags["t3"] = value;
    } else {
      delete tags["t3"];
    }
    this.setState({
      tags: tags
    });
    this.updateQueryParams(
      this.state.h1,
      this.state.h2,
      this.state.h3,
      this.state.h4,
      this.state.h5,
      tags
    );
  }

  _onSelectT4(options) {
    const value = options.map(o => {
      return o.value;
    });
    let tags = this.state.tags;
    if (value.length > 0) {
      tags["t4"] = value;
    } else {
      delete tags["t4"];
    }
    this.setState({
      tags: tags
    });
    this.updateQueryParams(
      this.state.h1,
      this.state.h2,
      this.state.h3,
      this.state.h4,
      this.state.h5,
      tags
    );
  }

  _onSelectT5(options) {
    const value = options.map(o => {
      return o.value;
    });
    let tags = this.state.tags;
    if (value.length > 0) {
      tags["t5"] = value;
    } else {
      delete tags["t5"];
    }
    this.setState({
      tags: tags
    });
    this.updateQueryParams(
      this.state.h1,
      this.state.h2,
      this.state.h3,
      this.state.h4,
      this.state.h5,
      tags
    );
  }

  _onSelectTT(options) {
    const value = options.map(o => {
      return o.value;
    });
    let tags = this.state.tags;
    if (value.length > 0) {
      tags["tt"] = value;
    } else {
      delete tags["tt"];
    }
    this.setState({
      tags: tags
    });
    this.updateQueryParams(
      this.state.h1,
      this.state.h2,
      this.state.h3,
      this.state.h4,
      this.state.h5,
      tags
    );
  }

  async _onSubmit() {
    this.setState({
      isLineupEnabled: false,
      isSubmitDisabled: true
    });
    try {
      const results = await DraftResource.postDraftLineup(
        this.state.h1,
        this.state.h2,
        this.state.h3,
        this.state.h4,
        this.state.h5
      );
      if (results.success) {
        this.setState({
          isSubmitDisabled: false,
          isLineupEnabled: true
        });
      } else {
        const jobInterval = setInterval(async () => {
          const job = await JobResource.getById(results.job.id);
          if (job.status === "finished") {
            clearInterval(this.state.jobInterval);
            this.setState({
              job: null,
              jobInterval: null,
              isSubmitDisabled: false,
              isLineupEnabled: true
            });
          } else {
            this.setState({
              job: job
            });
          }
        }, 2000);
        this.setState({
          jobInterval: jobInterval
        });
      }
    } catch (err) {
      this.setState({ isSubmitDisabled: false });
    }
  }

  _onSetHthreshold(value) {
    this.setState({
      hthreshold: value
    });
  }

  _onSetCsthreshold(value) {
    this.setState({
      csthreshold: value
    });
  }

  async _onQuery() {
    this.setState({
      isSubmitDisabled: false,
      isLineupEnabled: true
    });
  }

  async fetchLineup(state, instance) {
    this.setState({
      isLineupLoading: true
    });
    let h1 = this.state.h1,
      h2 = this.state.h2,
      h3 = this.state.h3,
      h4 = this.state.h4,
      h5 = this.state.h5;
    // Apply filters
    const filtered = state.filtered;
    if (filtered !== undefined && filtered !== null && filtered.length > 0) {
      filtered.forEach(filter => {
        if (HERO_NAME_TO_ID.hasOwnProperty(filter.value)) {
          let draft = [HERO_NAME_TO_ID[filter.value]];
          switch (filter.id) {
            case "draft.h0":
              h1 = draft;
              break;
            case "draft.h1":
              h2 = draft;
              break;
            case "draft.h2":
              h3 = draft;
              break;
            case "draft.h3":
              h4 = draft;
              break;
            case "draft.h4":
              h5 = draft;
              break;
            default:
              break;
          }
        }
      });
    }
    // Apply sorted
    let sortParams = [];
    const sorted = state.sorted;
    if (sorted !== undefined && sorted !== null && sorted.length > 0) {
      sorted.forEach(s => {
        const desc = s.desc ? "desc" : "asc";
        switch (s.id) {
          case "draft.h0":
            sortParams.push({ id: "s_1", value: desc });
            break;
          case "draft.h1":
            sortParams.push({ id: "s_2", value: desc });
            break;
          case "draft.h2":
            sortParams.push({ id: "s_3", value: desc });
            break;
          case "draft.h3":
            sortParams.push({ id: "s_4", value: desc });
            break;
          case "draft.h4":
            sortParams.push({ id: "s_5", value: desc });
            break;
          case "win_probability.blended":
            sortParams.push({ id: "s_w_b", value: desc });
            break;
          case "comfort_score":
            sortParams.push({ id: "s_cs", value: desc });
            break;
          case "win_probability.q1":
            sortParams.push({ id: "s_w_q1", value: desc });
            break;
          case "win_probability.q2":
            sortParams.push({ id: "s_w_q2", value: desc });
            break;
          case "win_probability.q3":
            sortParams.push({ id: "s_w_q3", value: desc });
            break;
          default:
            break;
        }
      });
    }
    // Apply tags
    const tags =
      Object.keys(this.state.tags).length > 0 ? this.state.tags : null;

    const data = await DraftResource.getDraftLineup(
      h1,
      h2,
      h3,
      h4,
      h5,
      this.state.hthreshold,
      this.state.csthreshold,
      state.page,
      state.pageSize,
      sortParams,
      tags
    );

    this.setState({
      lineup: data.rows,
      lineupPages:
        data.rows.length < state.pageSize ? state.page + 1 : state.page + 2,
      isLineupLoading: false
    });
  }

  updateQueryParams(h1, h2, h3, h4, h5, tags) {
    this.props.history.push({
      pathname: this.props.history.location.pathname,
      search:
        "?" +
        queryString.stringify({
          1: h1,
          2: h2,
          3: h3,
          4: h4,
          5: h5,
          t1: tags["t1"],
          t2: tags["t2"],
          t3: tags["t3"],
          t4: tags["t4"],
          t5: tags["t5"],
          tt: tags["tt"]
        })
    });
  }

  render() {
    return (
      <div className="container-fluid">
        <div className="pt-5 d-flex align-items-center">
          <div className="col-8">
            <Select
              options={HERO_SELECT_OPTIONS}
              defaultValue={this.state.defaultH1}
              formatOptionLabel={formatOptionLabel}
              placeholder={"Select Carry"}
              onChange={this._onSelectH1}
              isMulti
              isSearchable
            />

            <Select
              options={HERO_SELECT_OPTIONS}
              defaultValue={this.state.defaultH2}
              formatOptionLabel={formatOptionLabel}
              placeholder={"Select Mid"}
              onChange={this._onSelectH2}
              isMulti
              isSearchable
            />

            <Select
              options={HERO_SELECT_OPTIONS}
              defaultValue={this.state.defaultH3}
              formatOptionLabel={formatOptionLabel}
              placeholder={"Select Offlane"}
              onChange={this._onSelectH3}
              isMulti
              isSearchable
            />

            <Select
              options={HERO_SELECT_OPTIONS}
              defaultValue={this.state.defaultH4}
              formatOptionLabel={formatOptionLabel}
              placeholder={"Select Support (4)"}
              onChange={this._onSelectH4}
              isMulti
              isSearchable
            />

            <Select
              options={HERO_SELECT_OPTIONS}
              defaultValue={this.state.defaultH5}
              formatOptionLabel={formatOptionLabel}
              placeholder={"Select Support (5)"}
              onChange={this._onSelectH5}
              isMulti
              isSearchable
            />
          </div>

          <div className="col-4">
            <Select
              options={HERO_TAG_OPTIONS}
              defaultValue={this.state.defaultT1}
              placeholder={"Select Carry Tags"}
              onChange={this._onSelectT1}
              isMulti
              isSearchable
            />

            <Select
              options={HERO_TAG_OPTIONS}
              defaultValue={this.state.defaultT2}
              placeholder={"Select Mid Tags"}
              onChange={this._onSelectT2}
              isMulti
              isSearchable
            />

            <Select
              options={HERO_TAG_OPTIONS}
              defaultValue={this.state.defaultT3}
              placeholder={"Select Offlane Tags"}
              onChange={this._onSelectT3}
              isMulti
              isSearchable
            />

            <Select
              options={HERO_TAG_OPTIONS}
              defaultValue={this.state.defaultT4}
              placeholder={"Select Support(4) Tags"}
              onChange={this._onSelectT4}
              isMulti
              isSearchable
            />

            <Select
              options={HERO_TAG_OPTIONS}
              defaultValue={this.state.defaultT5}
              placeholder={"Select Support(5) Tags"}
              onChange={this._onSelectT5}
              isMulti
              isSearchable
            />
          </div>
        </div>

        <div className="pt-2 d-flex align-items-center">
          <div className="col-12">
            <Select
              options={HERO_TAG_OPTIONS}
              defaultValue={this.state.defaultTT}
              placeholder={"Select Team Tags"}
              onChange={this._onSelectTT}
              isMulti
              isSearchable
            />
          </div>
        </div>

        <div className="pt-2 pb-2 d-flex align-items-center">
          <div className="col-6">
            <SliderWithToolTip
              min={0}
              max={1}
              step={0.01}
              value={this.state.hthreshold}
              tipFormatter={value => `${value * 100}% minimum`}
              onChange={this._onSetHthreshold}
            />
          </div>

          <div className="col-2">
            <SliderWithToolTip
              min={0}
              max={7}
              step={0.1}
              value={this.state.csthreshold}
              tipFormatter={value =>
                `${value} minimum [equivalent of ${Math.pow(
                  30,
                  value / 5
                )} games played per player]`
              }
              onChange={this._onSetCsthreshold}
            />
          </div>

          <div className="col-2 text-center">
            <button
              className="btn btn-primary"
              type="submit"
              disabled={this.state.isSubmitDisabled}
              onClick={this._onSubmit}
            >
              Generate
            </button>
          </div>

          <div className="col-2 text-center">
            <button
              className="btn btn-primary"
              type="submit"
              disabled={this.state.isSubmitDisabled}
              onClick={this._onQuery}
            >
              Query
            </button>
          </div>
        </div>

        {this.state.job !== null ? (
          <div className="pt-5">
            {this.state.job.progress !== undefined &&
            this.state.job.progress !== null ? (
              <div className="progress">
                <div
                  className="progress-bar"
                  style={{
                    width: `${(
                      this.state.job.progress["progress"] /
                      this.state.job.progress["total"] *
                      100
                    ).toFixed()}%`
                  }}
                >
                  {this.state.job.progress["progress"]} /{" "}
                  {this.state.job.progress["total"]}
                </div>
              </div>
            ) : (
              <div className="progress">
                <div
                  className="progress-bar"
                  style={{
                    width: "0%"
                  }}
                >
                  Job is starting
                </div>
              </div>
            )}
          </div>
        ) : null}

        {this.state.isLineupEnabled ? (
          <ReactTable
            manual
            data={this.state.lineup}
            pages={this.state.lineupPages}
            loading={this.state.isLineupLoading}
            onFetchData={debounce(this.fetchLineup, 200)}
            columns={COLUMNS}
            SubComponent={renderMatchups}
            multiSort={false}
          />
        ) : null}
      </div>
    );
  }
}

export default DraftLineup;
