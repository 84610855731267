import React, { Component } from "react";
import Select from "react-select";
import "react-table/react-table.css";
import Slider from "rc-slider";
import "rc-slider/assets/index.css";
import _ from "lodash";
import * as queryString from "query-string";
import * as d3 from "d3";
import DraftResource from "../../resources/DraftResource";
import {
  getHeroIdsFromQueryParams,
  getHeroSelectFromQueryParams,
  getTagSelectFromQueryParams,
  getTagsFromQueryParams,
  HERO_SELECT_OPTIONS,
  HERO_TAG_OPTIONS,
  POSITION_SELECT_OPTIONS,
  zpad
} from "../../utils/HeroUtils";
import { formatOptionLabel } from "../../utils/SelectUtils";

const generateTree = (data, height, width) => {
  const root = d3.hierarchy(data);
  root.dx = height;
  root.dy = width / (root.height + 1);
  return d3.cluster().nodeSize([root.dx, root.dy])(root);
};

const generatePartition = (data, height, width) => {
  return d3
    .partition()
    .size([height, width])
    .padding(1)(d3.hierarchy(data).sum(d => 1));
};

const SliderWithToolTip = Slider.createSliderWithTooltip(Slider);

class DraftLineupTree extends Component {
  constructor(props) {
    super(props);
    const qs = queryString.parse(this.props.location.search);
    this.state = {
      h1: "1" in qs ? getHeroIdsFromQueryParams(qs["1"]) : [],
      h2: "2" in qs ? getHeroIdsFromQueryParams(qs["2"]) : [],
      h3: "3" in qs ? getHeroIdsFromQueryParams(qs["3"]) : [],
      h4: "4" in qs ? getHeroIdsFromQueryParams(qs["4"]) : [],
      h5: "5" in qs ? getHeroIdsFromQueryParams(qs["5"]) : [],
      defaultH1: "1" in qs ? getHeroSelectFromQueryParams(qs["1"]) : [],
      defaultH2: "2" in qs ? getHeroSelectFromQueryParams(qs["2"]) : [],
      defaultH3: "3" in qs ? getHeroSelectFromQueryParams(qs["3"]) : [],
      defaultH4: "4" in qs ? getHeroSelectFromQueryParams(qs["4"]) : [],
      defaultH5: "5" in qs ? getHeroSelectFromQueryParams(qs["5"]) : [],
      isSubmitDisabled: !(
        "1" in qs &&
        "2" in qs &&
        "3" in qs &&
        "4" in qs &&
        "5" in qs
      ),
      p1: null,
      p2: null,
      p3: null,
      p4: null,
      p5: null,
      pthreshold: 0.7,
      csthreshold: 5.0,
      tags: getTagsFromQueryParams(qs),
      defaultT1: "t1" in qs ? getTagSelectFromQueryParams(qs["t1"]) : [],
      defaultT2: "t2" in qs ? getTagSelectFromQueryParams(qs["t2"]) : [],
      defaultT3: "t3" in qs ? getTagSelectFromQueryParams(qs["t3"]) : [],
      defaultT4: "t4" in qs ? getTagSelectFromQueryParams(qs["t4"]) : [],
      defaultT5: "t5" in qs ? getTagSelectFromQueryParams(qs["t5"]) : [],
      defaultTT: "tt" in qs ? getTagSelectFromQueryParams(qs["tt"]) : [],
      gdata: null,
      gdataLength: 0,
      gdataKeys: [],
      isPSubmitDisabled: true
    };
    this._onSelectH1 = this._onSelectH1.bind(this);
    this._onSelectH2 = this._onSelectH2.bind(this);
    this._onSelectH3 = this._onSelectH3.bind(this);
    this._onSelectH4 = this._onSelectH4.bind(this);
    this._onSelectH5 = this._onSelectH5.bind(this);
    this._onSelectP1 = this._onSelectP1.bind(this);
    this._onSelectP2 = this._onSelectP2.bind(this);
    this._onSelectP3 = this._onSelectP3.bind(this);
    this._onSelectP4 = this._onSelectP4.bind(this);
    this._onSelectP5 = this._onSelectP5.bind(this);
    this._onSelectT1 = this._onSelectT1.bind(this);
    this._onSelectT2 = this._onSelectT2.bind(this);
    this._onSelectT3 = this._onSelectT3.bind(this);
    this._onSelectT4 = this._onSelectT4.bind(this);
    this._onSelectT5 = this._onSelectT5.bind(this);
    this._onSelectTT = this._onSelectTT.bind(this);
    this.updateQueryParams = this.updateQueryParams.bind(this);
    this._onSetPthreshold = this._onSetPthreshold.bind(this);
    this._onSetCsthreshold = this._onSetCsthreshold.bind(this);
    this._onPSubmit = this._onPSubmit.bind(this);
    this._plotTree = this._plotTree.bind(this);
    this._plotPartition = this._plotPartition.bind(this);
  }

  static __isSubmitDisabled(h1, h2, h3, h4, h5, p1, p2, p3, p4, p5) {
    return !(
      [h1, h2, h3, h4, h5, p1, p2, p3, p4, p5].every(value => {
        if (value === undefined || value === null) {
          return false;
        }
        if (Array.isArray(value)) {
          return value.length > 0;
        }
        return true;
      }) && _.uniq([p1, p2, p3, p4, p5]).length === 5
    );
  }

  _onSelectH1(options) {
    const value = options.map(o => {
      return o.value;
    });
    this.setState({
      h1: value,
      isSubmitDisabled: DraftLineupTree.__isSubmitDisabled(
        options,
        this.state.h2,
        this.state.h3,
        this.state.h4,
        this.state.h5,
        this.state.p1,
        this.state.p2,
        this.state.p3,
        this.state.p4,
        this.state.p5
      )
    });
    this.updateQueryParams(
      value,
      this.state.h2,
      this.state.h3,
      this.state.h4,
      this.state.h5,
      this.state.tags
    );
  }

  _onSelectH2(options) {
    const value = options.map(o => {
      return o.value;
    });
    this.setState({
      h2: value,
      isSubmitDisabled: DraftLineupTree.__isSubmitDisabled(
        this.state.h1,
        options,
        this.state.h3,
        this.state.h4,
        this.state.h5,
        this.state.p1,
        this.state.p2,
        this.state.p3,
        this.state.p4,
        this.state.p5
      )
    });
    this.updateQueryParams(
      this.state.h1,
      value,
      this.state.h3,
      this.state.h4,
      this.state.h5,
      this.state.tags
    );
  }

  _onSelectH3(options) {
    const value = options.map(o => {
      return o.value;
    });
    this.setState({
      h3: value,
      isSubmitDisabled: DraftLineupTree.__isSubmitDisabled(
        this.state.h1,
        this.state.h2,
        options,
        this.state.h4,
        this.state.h5,
        this.state.p1,
        this.state.p2,
        this.state.p3,
        this.state.p4,
        this.state.p5
      )
    });
    this.updateQueryParams(
      this.state.h1,
      this.state.h2,
      value,
      this.state.h4,
      this.state.h5,
      this.state.tags
    );
  }

  _onSelectH4(options) {
    const value = options.map(o => {
      return o.value;
    });
    this.setState({
      h4: value,
      isSubmitDisabled: DraftLineupTree.__isSubmitDisabled(
        this.state.h1,
        this.state.h2,
        this.state.h3,
        options,
        this.state.h5,
        this.state.p1,
        this.state.p2,
        this.state.p3,
        this.state.p4,
        this.state.p5
      )
    });
    this.updateQueryParams(
      this.state.h1,
      this.state.h2,
      this.state.h3,
      value,
      this.state.h5,
      this.state.tags
    );
  }

  _onSelectH5(options) {
    const value = options.map(o => {
      return o.value;
    });
    this.setState({
      h5: value,
      isSubmitDisabled: DraftLineupTree.__isSubmitDisabled(
        this.state.h1,
        this.state.h2,
        this.state.h3,
        this.state.h4,
        options,
        this.state.p1,
        this.state.p2,
        this.state.p3,
        this.state.p4,
        this.state.p5
      )
    });
    this.updateQueryParams(
      this.state.h1,
      this.state.h2,
      this.state.h3,
      this.state.h4,
      value,
      this.state.tags
    );
  }

  _onSelectP1(options) {
    this.setState({
      p1: options.value,
      isSubmitDisabled: DraftLineupTree.__isSubmitDisabled(
        this.state.h1,
        this.state.h2,
        this.state.h3,
        this.state.h4,
        this.state.h5,
        options.value,
        this.state.p2,
        this.state.p3,
        this.state.p4,
        this.state.p5
      )
    });
  }

  _onSelectP2(options) {
    this.setState({
      p2: options.value,
      isSubmitDisabled: DraftLineupTree.__isSubmitDisabled(
        this.state.h1,
        this.state.h2,
        this.state.h3,
        this.state.h4,
        this.state.h5,
        this.state.p1,
        options.value,
        this.state.p3,
        this.state.p4,
        this.state.p5
      )
    });
  }

  _onSelectP3(options) {
    this.setState({
      p3: options.value,
      isSubmitDisabled: DraftLineupTree.__isSubmitDisabled(
        this.state.h1,
        this.state.h2,
        this.state.h3,
        this.state.h4,
        this.state.h5,
        this.state.p1,
        this.state.p2,
        options.value,
        this.state.p4,
        this.state.p5
      )
    });
  }

  _onSelectP4(options) {
    this.setState({
      p4: options.value,
      isSubmitDisabled: DraftLineupTree.__isSubmitDisabled(
        this.state.h1,
        this.state.h2,
        this.state.h3,
        this.state.h4,
        this.state.h5,
        this.state.p1,
        this.state.p2,
        this.state.p3,
        options.value,
        this.state.p5
      )
    });
  }

  _onSelectP5(options) {
    this.setState({
      p5: options.value,
      isSubmitDisabled: DraftLineupTree.__isSubmitDisabled(
        this.state.h1,
        this.state.h2,
        this.state.h3,
        this.state.h4,
        this.state.h5,
        this.state.p1,
        this.state.p2,
        this.state.p3,
        this.state.p4,
        options.value
      )
    });
  }

  _onSelectT1(options) {
    const value = options.map(o => {
      return o.value;
    });
    let tags = this.state.tags;
    if (value.length > 0) {
      tags["t1"] = value;
    } else {
      delete tags["t1"];
    }
    this.setState({
      tags: tags
    });
    this.updateQueryParams(
      this.state.h1,
      this.state.h2,
      this.state.h3,
      this.state.h4,
      this.state.h5,
      tags
    );
  }

  _onSelectT2(options) {
    const value = options.map(o => {
      return o.value;
    });
    let tags = this.state.tags;
    if (value.length > 0) {
      tags["t2"] = value;
    } else {
      delete tags["t2"];
    }
    this.setState({
      tags: tags
    });
    this.updateQueryParams(
      this.state.h1,
      this.state.h2,
      this.state.h3,
      this.state.h4,
      this.state.h5,
      tags
    );
  }

  _onSelectT3(options) {
    const value = options.map(o => {
      return o.value;
    });
    let tags = this.state.tags;
    if (value.length > 0) {
      tags["t3"] = value;
    } else {
      delete tags["t3"];
    }
    this.setState({
      tags: tags
    });
    this.updateQueryParams(
      this.state.h1,
      this.state.h2,
      this.state.h3,
      this.state.h4,
      this.state.h5,
      tags
    );
  }

  _onSelectT4(options) {
    const value = options.map(o => {
      return o.value;
    });
    let tags = this.state.tags;
    if (value.length > 0) {
      tags["t4"] = value;
    } else {
      delete tags["t4"];
    }
    this.setState({
      tags: tags
    });
    this.updateQueryParams(
      this.state.h1,
      this.state.h2,
      this.state.h3,
      this.state.h4,
      this.state.h5,
      tags
    );
  }

  _onSelectT5(options) {
    const value = options.map(o => {
      return o.value;
    });
    let tags = this.state.tags;
    if (value.length > 0) {
      tags["t5"] = value;
    } else {
      delete tags["t5"];
    }
    this.setState({
      tags: tags
    });
    this.updateQueryParams(
      this.state.h1,
      this.state.h2,
      this.state.h3,
      this.state.h4,
      this.state.h5,
      tags
    );
  }

  _onSelectTT(options) {
    const value = options.map(o => {
      return o.value;
    });
    let tags = this.state.tags;
    if (value.length > 0) {
      tags["tt"] = value;
    } else {
      delete tags["tt"];
    }
    this.setState({
      tags: tags
    });
    this.updateQueryParams(
      this.state.h1,
      this.state.h2,
      this.state.h3,
      this.state.h4,
      this.state.h5,
      tags
    );
  }

  updateQueryParams(h1, h2, h3, h4, h5, tags) {
    this.props.history.push({
      pathname: this.props.history.location.pathname,
      search:
        "?" +
        queryString.stringify({
          1: h1,
          2: h2,
          3: h3,
          4: h4,
          5: h5,
          t1: tags["t1"],
          t2: tags["t2"],
          t3: tags["t3"],
          t4: tags["t4"],
          t5: tags["t5"],
          tt: tags["tt"]
        })
    });
  }

  _onSetPthreshold(value) {
    this.setState({
      pthreshold: value
    });
  }

  _onSetCsthreshold(value) {
    this.setState({
      csthreshold: value
    });
  }

  async _onPSubmit() {
    this.setState({
      isSubmitDisabled: true
    });

    try {
      // Apply tags
      const tags =
        Object.keys(this.state.tags).length > 0 ? this.state.tags : null;
      const data = await DraftResource.getDraftLineupParcat(
          this.state.h1,
          this.state.h2,
          this.state.h3,
          this.state.h4,
          this.state.h5,
          this.state.pthreshold,
          this.state.csthreshold,
          tags
        ),
        drafts = _.range(data["h0"].length).map(i => {
          return [
            data["h0"][i],
            data["h1"][i],
            data["h2"][i],
            data["h3"][i],
            data["h4"][i]
          ];
        }),
        g0 = _.groupBy(drafts, i => {
          return i[parseInt(this.state.p1)];
        }),
        g0_k = Object.keys(g0);
      g0_k.sort((a, b) => {
        return g0[b].length - g0[a].length;
      });

      let gDataLength = [];

      const gdata = g0_k.map(hid => {
        gDataLength.push(g0[hid].length);
        const g1 = _.groupBy(g0[hid], i => {
            return i[parseInt(this.state.p2)];
          }),
          g1_k = Object.keys(g1);
        g1_k.sort((a, b) => {
          return g1[b].length - g1[a].length;
        });

        const c1 = g1_k.map(hid => {
          const g2 = _.groupBy(g1[hid], i => {
              return i[parseInt(this.state.p3)];
            }),
            g2_k = Object.keys(g2);
          g2_k.sort((a, b) => {
            return g2[b].length - g2[a].length;
          });

          const c2 = g2_k.map(hid => {
            const g3 = _.groupBy(g2[hid], i => {
                return i[parseInt(this.state.p4)];
              }),
              g3_k = Object.keys(g3);
            g3_k.sort((a, b) => {
              return g3[b].length - g3[a].length;
            });

            const c3 = g3_k.map(hid => {
              const g4 = _.groupBy(g3[hid], i => {
                  return i[parseInt(this.state.p5)];
                }),
                g4_k = Object.keys(g4);
              g4_k.sort((a, b) => {
                return g4[b].length - g4[a].length;
              });

              const c4 = g4_k.map(hid => {
                return {
                  id: parseInt(hid)
                };
              });
              return {
                id: parseInt(hid),
                children: c4
              };
            });
            return {
              id: parseInt(hid),
              children: c3
            };
          });
          return {
            id: parseInt(hid),
            children: c2
          };
        });
        return {
          id: parseInt(hid),
          children: c1
        };
      });
      this.setState({
        gdata: gdata,
        gdataLength: gDataLength,
        gdataKeys: g0_k
      });
      setTimeout(() => {
        this._plotPartition(0);
      }, 10);
    } catch (e) {
      // Do nothing
    }

    this.setState({
      isSubmitDisabled: false
    });
  }

  _plotTree(index) {
    const width = window.innerWidth - 100,
      iconHeight = 30,
      root = generateTree(this.state.gdata[index], iconHeight, width);

    let x0 = Infinity,
      x1 = -x0;
    root.each(d => {
      if (d.x > x1) x1 = d.x;
      if (d.x < x0) x0 = d.x;
    });

    d3.selectAll("#gdata > *").remove();

    const svg = d3
      .select("#gdata")
      .append("svg")
      .style("width", width)
      .style("height", this.state.gdataLength[index] * (iconHeight * 2));

    const g = svg
      .append("g")
      .attr("font-family", "sans-serif")
      .attr("font-size", 10)
      .attr("transform", `translate(${root.dy / 3},${root.dx - x0})`);

    g
      .append("g")
      .attr("fill", "none")
      .attr("stroke", "#555")
      .attr("stroke-opacity", 0.4)
      .attr("stroke-width", 1.5)
      .selectAll("path")
      .data(root.links())
      .join("path")
      .attr(
        "d",
        d => `
        M${d.target.y},${d.target.x}
        C${d.source.y + root.dy / 2},${d.target.x}
         ${d.source.y + root.dy / 2},${d.source.x}
         ${d.source.y},${d.source.x}
      `
      );

    const node = g
      .append("g")
      .attr("stroke-linejoin", "round")
      .attr("stroke-width", 3)
      .selectAll("g")
      .data(root.descendants().reverse())
      .join("g")
      .attr("transform", d => `translate(${d.y},${d.x})`);

    node
      .append("image")
      .attr("xlink:href", d => {
        return `/images/heroes/h/${zpad(d.data.id, 3)}.png`;
      })
      .attr("x", d => {
        return -(iconHeight / 2);
      })
      .attr("y", d => {
        return -(iconHeight / 2);
      })
      .attr("height", iconHeight)
      .attr("width", iconHeight);
    svg.node();
  }

  _plotPartition(index) {
    const minimumIconHeight = 40,
      width = 256 * (minimumIconHeight / 144) * 5 * 1.5,
      root = generatePartition(
        this.state.gdata[index],
        this.state.gdataLength[index] * minimumIconHeight,
        width
      ),
      color = d3.scaleOrdinal(d3.schemeSet2);

    d3.selectAll("#gdata > *").remove();

    const svg = d3
      .select("#gdata")
      .append("svg")
      .style("width", width)
      .style("height", this.state.gdataLength[index] * minimumIconHeight);

    const cell = svg
      .selectAll("g")
      .data(root.descendants())
      .join("g")
      .attr("transform", d => `translate(${d.y0},${d.x0})`);

    cell
      .append("rect")
      .attr("width", d => d.y1 - d.y0)
      .attr("height", d => d.x1 - d.x0)
      .attr("fill-opacity", 0.5)
      .attr("fill", d => {
        return color(d.depth);
      });

    cell
      .append("image")
      .attr("xlink:href", d => {
        return `/images/heroes/h/${zpad(d.data.id, 3)}.png`;
      })
      .attr("width", d => d.y1 - d.y0)
      .attr("height", d => d.x1 - d.x0);
    svg.node();
  }

  render() {
    return (
      <div className="container-fluid">
        <div className="pt-5 d-flex align-items-center">
          <div className="col-8">
            <Select
              options={HERO_SELECT_OPTIONS}
              defaultValue={this.state.defaultH1}
              formatOptionLabel={formatOptionLabel}
              placeholder={"Select Carry"}
              onChange={this._onSelectH1}
              isMulti
              isSearchable
            />

            <Select
              options={HERO_SELECT_OPTIONS}
              defaultValue={this.state.defaultH2}
              formatOptionLabel={formatOptionLabel}
              placeholder={"Select Mid"}
              onChange={this._onSelectH2}
              isMulti
              isSearchable
            />

            <Select
              options={HERO_SELECT_OPTIONS}
              defaultValue={this.state.defaultH3}
              formatOptionLabel={formatOptionLabel}
              placeholder={"Select Offlane"}
              onChange={this._onSelectH3}
              isMulti
              isSearchable
            />

            <Select
              options={HERO_SELECT_OPTIONS}
              defaultValue={this.state.defaultH4}
              formatOptionLabel={formatOptionLabel}
              placeholder={"Select Support (4)"}
              onChange={this._onSelectH4}
              isMulti
              isSearchable
            />

            <Select
              options={HERO_SELECT_OPTIONS}
              defaultValue={this.state.defaultH5}
              formatOptionLabel={formatOptionLabel}
              placeholder={"Select Support (5)"}
              onChange={this._onSelectH5}
              isMulti
              isSearchable
            />
          </div>

          <div className="col-4">
            <Select
              options={HERO_TAG_OPTIONS}
              defaultValue={this.state.defaultT1}
              placeholder={"Select Carry Tags"}
              onChange={this._onSelectT1}
              isMulti
              isSearchable
            />

            <Select
              options={HERO_TAG_OPTIONS}
              defaultValue={this.state.defaultT2}
              placeholder={"Select Mid Tags"}
              onChange={this._onSelectT2}
              isMulti
              isSearchable
            />

            <Select
              options={HERO_TAG_OPTIONS}
              defaultValue={this.state.defaultT3}
              placeholder={"Select Offlane Tags"}
              onChange={this._onSelectT3}
              isMulti
              isSearchable
            />

            <Select
              options={HERO_TAG_OPTIONS}
              defaultValue={this.state.defaultT4}
              placeholder={"Select Support(4) Tags"}
              onChange={this._onSelectT4}
              isMulti
              isSearchable
            />

            <Select
              options={HERO_TAG_OPTIONS}
              defaultValue={this.state.defaultT5}
              placeholder={"Select Support(5) Tags"}
              onChange={this._onSelectT5}
              isMulti
              isSearchable
            />
          </div>
        </div>

        <div className="pt-2 d-flex align-items-center">
          <div className="col-12">
            <Select
              options={HERO_TAG_OPTIONS}
              defaultValue={this.state.defaultTT}
              placeholder={"Select Team Tags"}
              onChange={this._onSelectTT}
              isMulti
              isSearchable
            />
          </div>
        </div>

        <div className="pt-2 pb-2 d-flex align-items-center">
          <div className="col-2">
            <Select
              options={POSITION_SELECT_OPTIONS}
              placeholder={"Select Pick 1"}
              onChange={this._onSelectP1}
            />
          </div>
          <div className="col-2">
            <Select
              options={POSITION_SELECT_OPTIONS}
              placeholder={"Select Pick 2"}
              onChange={this._onSelectP2}
            />
          </div>
          <div className="col-2">
            <Select
              options={POSITION_SELECT_OPTIONS}
              placeholder={"Select Pick 3"}
              onChange={this._onSelectP3}
            />
          </div>
          <div className="col-2">
            <Select
              options={POSITION_SELECT_OPTIONS}
              placeholder={"Select Pick 4"}
              onChange={this._onSelectP4}
            />
          </div>
          <div className="col-2">
            <Select
              options={POSITION_SELECT_OPTIONS}
              placeholder={"Select Pick 5"}
              onChange={this._onSelectP5}
            />
          </div>
        </div>

        <div className="pt-2 pb-2 d-flex align-items-center">
          <div className="col-4">
            <SliderWithToolTip
              min={0}
              max={1}
              step={0.01}
              value={this.state.pthreshold}
              tipFormatter={value => `${value * 100}% minimum`}
              onChange={this._onSetPthreshold}
            />
          </div>

          <div className="col-2">
            <SliderWithToolTip
              min={0}
              max={7}
              step={0.1}
              value={this.state.csthreshold}
              tipFormatter={value =>
                `${value} minimum [equivalent of ${Math.pow(
                  30,
                  value / 5
                )} games played per player]`
              }
              onChange={this._onSetCsthreshold}
            />
          </div>

          <div className="col-4" />

          <div className="col-2 text-center">
            <button
              className="btn btn-primary"
              type="submit"
              disabled={this.state.isSubmitDisabled}
              onClick={this._onPSubmit}
            >
              Query
            </button>
          </div>
        </div>

        <div className="pt-2 pb-2 d-flex align-items-center">
          <ul className="list-inline">
            {this.state.gdataKeys.map((heroId, i) => {
              return (
                <li key={heroId} className="list-inline-item">
                  <button
                    type="button"
                    onClick={() => {
                      this._plotPartition(i);
                    }}
                  >
                    <img
                      src={`/images/heroes/h/${zpad(heroId, 3)}.png`}
                      style={{ height: "1.78em" }}
                      alt={""}
                    />
                  </button>
                </li>
              );
            })}
          </ul>
        </div>

        <div id={"gdata"} />
      </div>
    );
  }
}

export default DraftLineupTree;
