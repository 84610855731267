import React, { Component } from "react";
import Select from "react-select";
import "react-table/react-table.css";
import Slider from "rc-slider";
import "rc-slider/assets/index.css";
import Plot from "react-plotly.js";
import _ from "lodash";
import * as queryString from "query-string";
import DraftResource from "../../resources/DraftResource";
import {
  getHeroIdsFromQueryParams,
  getHeroSelectFromQueryParams,
  getPositionsFromKey,
  getTagSelectFromQueryParams,
  getTagsFromQueryParams,
  HERO_ID_TO_PROPS,
  HERO_SELECT_OPTIONS,
  HERO_TAG_OPTIONS
} from "../../utils/HeroUtils";
import { formatOptionLabel } from "../../utils/SelectUtils";

const SliderWithToolTip = Slider.createSliderWithTooltip(Slider);

class DraftLineupParcat extends Component {
  constructor(props) {
    super(props);
    const qs = queryString.parse(this.props.location.search);
    this.state = {
      h1: "1" in qs ? getHeroIdsFromQueryParams(qs["1"]) : [],
      h2: "2" in qs ? getHeroIdsFromQueryParams(qs["2"]) : [],
      h3: "3" in qs ? getHeroIdsFromQueryParams(qs["3"]) : [],
      h4: "4" in qs ? getHeroIdsFromQueryParams(qs["4"]) : [],
      h5: "5" in qs ? getHeroIdsFromQueryParams(qs["5"]) : [],
      defaultH1: "1" in qs ? getHeroSelectFromQueryParams(qs["1"]) : [],
      defaultH2: "2" in qs ? getHeroSelectFromQueryParams(qs["2"]) : [],
      defaultH3: "3" in qs ? getHeroSelectFromQueryParams(qs["3"]) : [],
      defaultH4: "4" in qs ? getHeroSelectFromQueryParams(qs["4"]) : [],
      defaultH5: "5" in qs ? getHeroSelectFromQueryParams(qs["5"]) : [],
      isSubmitDisabled: !(
        "1" in qs &&
        "2" in qs &&
        "3" in qs &&
        "4" in qs &&
        "5" in qs
      ),
      pthreshold: 0.7,
      csthreshold: 5.0,
      tags: getTagsFromQueryParams(qs),
      defaultT1: "t1" in qs ? getTagSelectFromQueryParams(qs["t1"]) : [],
      defaultT2: "t2" in qs ? getTagSelectFromQueryParams(qs["t2"]) : [],
      defaultT3: "t3" in qs ? getTagSelectFromQueryParams(qs["t3"]) : [],
      defaultT4: "t4" in qs ? getTagSelectFromQueryParams(qs["t4"]) : [],
      defaultT5: "t5" in qs ? getTagSelectFromQueryParams(qs["t5"]) : [],
      defaultTT: "tt" in qs ? getTagSelectFromQueryParams(qs["tt"]) : [],
      data: null,
      pdata: null,
      isPSubmitDisabled: true,
      plotH1: true,
      plotH2: true,
      plotH3: true,
      plotH4: true,
      plotH5: true
    };
    this._onSelectH1 = this._onSelectH1.bind(this);
    this._onSelectH2 = this._onSelectH2.bind(this);
    this._onSelectH3 = this._onSelectH3.bind(this);
    this._onSelectH4 = this._onSelectH4.bind(this);
    this._onSelectH5 = this._onSelectH5.bind(this);
    this._onSelectT1 = this._onSelectT1.bind(this);
    this._onSelectT2 = this._onSelectT2.bind(this);
    this._onSelectT3 = this._onSelectT3.bind(this);
    this._onSelectT4 = this._onSelectT4.bind(this);
    this._onSelectT5 = this._onSelectT5.bind(this);
    this._onSelectTT = this._onSelectTT.bind(this);
    this.updateQueryParams = this.updateQueryParams.bind(this);
    this._onSetPthreshold = this._onSetPthreshold.bind(this);
    this._onSetCsthreshold = this._onSetCsthreshold.bind(this);
    this._onPSubmit = this._onPSubmit.bind(this);
    this._onSetPlot = this._onSetPlot.bind(this);
    this._updatePlot = this._updatePlot.bind(this);
  }

  static __isSubmitDisabled(h1, h2, h3, h4, h5) {
    return ![h1, h2, h3, h4, h5].every(h => {
      return h.length > 0;
    });
  }

  _onSelectH1(options) {
    const value = options.map(o => {
      return o.value;
    });
    this.setState({
      h1: value,
      isSubmitDisabled: DraftLineupParcat.__isSubmitDisabled(
        options,
        this.state.h2,
        this.state.h3,
        this.state.h4,
        this.state.h5
      )
    });
    this.updateQueryParams(
      value,
      this.state.h2,
      this.state.h3,
      this.state.h4,
      this.state.h5,
      this.state.tags
    );
  }

  _onSelectH2(options) {
    const value = options.map(o => {
      return o.value;
    });
    this.setState({
      h2: value,
      isSubmitDisabled: DraftLineupParcat.__isSubmitDisabled(
        this.state.h1,
        options,
        this.state.h3,
        this.state.h4,
        this.state.h5
      )
    });
    this.updateQueryParams(
      this.state.h1,
      value,
      this.state.h3,
      this.state.h4,
      this.state.h5,
      this.state.tags
    );
  }

  _onSelectH3(options) {
    const value = options.map(o => {
      return o.value;
    });
    this.setState({
      h3: value,
      isSubmitDisabled: DraftLineupParcat.__isSubmitDisabled(
        this.state.h1,
        this.state.h2,
        options,
        this.state.h4,
        this.state.h5
      )
    });
    this.updateQueryParams(
      this.state.h1,
      this.state.h2,
      value,
      this.state.h4,
      this.state.h5,
      this.state.tags
    );
  }

  _onSelectH4(options) {
    const value = options.map(o => {
      return o.value;
    });
    this.setState({
      h4: value,
      isSubmitDisabled: DraftLineupParcat.__isSubmitDisabled(
        this.state.h1,
        this.state.h2,
        this.state.h3,
        options,
        this.state.h5
      )
    });
    this.updateQueryParams(
      this.state.h1,
      this.state.h2,
      this.state.h3,
      value,
      this.state.h5,
      this.state.tags
    );
  }

  _onSelectH5(options) {
    const value = options.map(o => {
      return o.value;
    });
    this.setState({
      h5: value,
      isSubmitDisabled: DraftLineupParcat.__isSubmitDisabled(
        this.state.h1,
        this.state.h2,
        this.state.h3,
        this.state.h4,
        options
      )
    });
    this.updateQueryParams(
      this.state.h1,
      this.state.h2,
      this.state.h3,
      this.state.h4,
      value,
      this.state.tags
    );
  }

  _onSelectT1(options) {
    const value = options.map(o => {
      return o.value;
    });
    let tags = this.state.tags;
    if (value.length > 0) {
      tags["t1"] = value;
    } else {
      delete tags["t1"];
    }
    this.setState({
      tags: tags
    });
    this.updateQueryParams(
      this.state.h1,
      this.state.h2,
      this.state.h3,
      this.state.h4,
      this.state.h5,
      tags
    );
  }

  _onSelectT2(options) {
    const value = options.map(o => {
      return o.value;
    });
    let tags = this.state.tags;
    if (value.length > 0) {
      tags["t2"] = value;
    } else {
      delete tags["t2"];
    }
    this.setState({
      tags: tags
    });
    this.updateQueryParams(
      this.state.h1,
      this.state.h2,
      this.state.h3,
      this.state.h4,
      this.state.h5,
      tags
    );
  }

  _onSelectT3(options) {
    const value = options.map(o => {
      return o.value;
    });
    let tags = this.state.tags;
    if (value.length > 0) {
      tags["t3"] = value;
    } else {
      delete tags["t3"];
    }
    this.setState({
      tags: tags
    });
    this.updateQueryParams(
      this.state.h1,
      this.state.h2,
      this.state.h3,
      this.state.h4,
      this.state.h5,
      tags
    );
  }

  _onSelectT4(options) {
    const value = options.map(o => {
      return o.value;
    });
    let tags = this.state.tags;
    if (value.length > 0) {
      tags["t4"] = value;
    } else {
      delete tags["t4"];
    }
    this.setState({
      tags: tags
    });
    this.updateQueryParams(
      this.state.h1,
      this.state.h2,
      this.state.h3,
      this.state.h4,
      this.state.h5,
      tags
    );
  }

  _onSelectT5(options) {
    const value = options.map(o => {
      return o.value;
    });
    let tags = this.state.tags;
    if (value.length > 0) {
      tags["t5"] = value;
    } else {
      delete tags["t5"];
    }
    this.setState({
      tags: tags
    });
    this.updateQueryParams(
      this.state.h1,
      this.state.h2,
      this.state.h3,
      this.state.h4,
      this.state.h5,
      tags
    );
  }

  _onSelectTT(options) {
    const value = options.map(o => {
      return o.value;
    });
    let tags = this.state.tags;
    if (value.length > 0) {
      tags["tt"] = value;
    } else {
      delete tags["tt"];
    }
    this.setState({
      tags: tags
    });
    this.updateQueryParams(
      this.state.h1,
      this.state.h2,
      this.state.h3,
      this.state.h4,
      this.state.h5,
      tags
    );
  }

  updateQueryParams(h1, h2, h3, h4, h5, tags) {
    this.props.history.push({
      pathname: this.props.history.location.pathname,
      search:
        "?" +
        queryString.stringify({
          1: h1,
          2: h2,
          3: h3,
          4: h4,
          5: h5,
          t1: tags["t1"],
          t2: tags["t2"],
          t3: tags["t3"],
          t4: tags["t4"],
          t5: tags["t5"],
          tt: tags["tt"]
        })
    });
  }

  _onSetPthreshold(value) {
    this.setState({
      pthreshold: value
    });
  }

  _onSetCsthreshold(value) {
    this.setState({
      csthreshold: value
    });
  }

  _onSetPlot(event) {
    const target = event.target;
    const value = target.type === "checkbox" ? target.checked : target.value;
    const name = target.name;

    this.setState({
      [name]: value
    });
    setTimeout(this._updatePlot, 10);
  }

  _updatePlot() {
    if (this.state.data !== null) {
      // Determine which index to plot
      const data = this.state.data;
      let positionToPlot = [];
      if (this.state.plotH1) {
        positionToPlot.push("h0");
      }
      if (this.state.plotH2) {
        positionToPlot.push("h1");
      }
      if (this.state.plotH3) {
        positionToPlot.push("h2");
      }
      if (this.state.plotH4) {
        positionToPlot.push("h3");
      }
      if (this.state.plotH5) {
        positionToPlot.push("h4");
      }

      const dimensions = positionToPlot.map(i => {
          const heroIds = _.uniq(data[i]),
            ticktext = heroIds.map(heroId => {
              return HERO_ID_TO_PROPS[heroId].name;
            });
          return {
            values: data[i],
            label: getPositionsFromKey(i),
            categoryarray: heroIds,
            ticktext: ticktext
          };
        }),
        colors = _.uniq(
          _.flattenDeep(
            positionToPlot.map(i => {
              return _.uniq(data[i]);
            })
          )
        );
      this.setState({
        pdata: [
          {
            type: "parcats",
            dimensions: dimensions,
            arrangement: "freeform",
            line: {
              color: colors,
              colorscale: "Electric"
            }
          }
        ]
      });
    }
  }

  async _onPSubmit() {
    this.setState({
      isPSubmitDisabled: true
    });

    try {
      // Apply tags
      const tags =
        Object.keys(this.state.tags).length > 0 ? this.state.tags : null;
      const data = await DraftResource.getDraftLineupParcat(
        this.state.h1,
        this.state.h2,
        this.state.h3,
        this.state.h4,
        this.state.h5,
        this.state.pthreshold,
        this.state.csthreshold,
        tags
      );

      this.setState({
        data: data
      });

      setTimeout(this._updatePlot, 10);
    } catch (e) {
      // Do nothing
    }

    this.setState({
      isPSubmitDisabled: false
    });
  }

  render() {
    return (
      <div className="container-fluid">
        <div className="pt-5 d-flex align-items-center">
          <div className="col-8">
            <Select
              options={HERO_SELECT_OPTIONS}
              defaultValue={this.state.defaultH1}
              formatOptionLabel={formatOptionLabel}
              placeholder={"Select Carry"}
              onChange={this._onSelectH1}
              isMulti
              isSearchable
            />

            <Select
              options={HERO_SELECT_OPTIONS}
              defaultValue={this.state.defaultH2}
              formatOptionLabel={formatOptionLabel}
              placeholder={"Select Mid"}
              onChange={this._onSelectH2}
              isMulti
              isSearchable
            />

            <Select
              options={HERO_SELECT_OPTIONS}
              defaultValue={this.state.defaultH3}
              formatOptionLabel={formatOptionLabel}
              placeholder={"Select Offlane"}
              onChange={this._onSelectH3}
              isMulti
              isSearchable
            />

            <Select
              options={HERO_SELECT_OPTIONS}
              defaultValue={this.state.defaultH4}
              formatOptionLabel={formatOptionLabel}
              placeholder={"Select Support (4)"}
              onChange={this._onSelectH4}
              isMulti
              isSearchable
            />

            <Select
              options={HERO_SELECT_OPTIONS}
              defaultValue={this.state.defaultH5}
              formatOptionLabel={formatOptionLabel}
              placeholder={"Select Support (5)"}
              onChange={this._onSelectH5}
              isMulti
              isSearchable
            />
          </div>

          <div className="col-4">
            <Select
              options={HERO_TAG_OPTIONS}
              defaultValue={this.state.defaultT1}
              placeholder={"Select Carry Tags"}
              onChange={this._onSelectT1}
              isMulti
              isSearchable
            />

            <Select
              options={HERO_TAG_OPTIONS}
              defaultValue={this.state.defaultT2}
              placeholder={"Select Mid Tags"}
              onChange={this._onSelectT2}
              isMulti
              isSearchable
            />

            <Select
              options={HERO_TAG_OPTIONS}
              defaultValue={this.state.defaultT3}
              placeholder={"Select Offlane Tags"}
              onChange={this._onSelectT3}
              isMulti
              isSearchable
            />

            <Select
              options={HERO_TAG_OPTIONS}
              defaultValue={this.state.defaultT4}
              placeholder={"Select Support(4) Tags"}
              onChange={this._onSelectT4}
              isMulti
              isSearchable
            />

            <Select
              options={HERO_TAG_OPTIONS}
              defaultValue={this.state.defaultT5}
              placeholder={"Select Support(5) Tags"}
              onChange={this._onSelectT5}
              isMulti
              isSearchable
            />
          </div>
        </div>

        <div className="pt-2 d-flex align-items-center">
          <div className="col-12">
            <Select
              options={HERO_TAG_OPTIONS}
              defaultValue={this.state.defaultTT}
              placeholder={"Select Team Tags"}
              onChange={this._onSelectTT}
              isMulti
              isSearchable
            />
          </div>
        </div>

        <div className="pt-2 pb-2 d-flex align-items-center">
          <div className="col-4">
            <SliderWithToolTip
              min={0}
              max={1}
              step={0.01}
              value={this.state.pthreshold}
              tipFormatter={value => `${value * 100}% minimum`}
              onChange={this._onSetPthreshold}
            />
          </div>

          <div className="col-2">
            <SliderWithToolTip
              min={0}
              max={7}
              step={0.1}
              value={this.state.csthreshold}
              tipFormatter={value =>
                `${value} minimum [equivalent of ${Math.pow(
                  30,
                  value / 5
                )} games played per player]`
              }
              onChange={this._onSetCsthreshold}
            />
          </div>

          <div className="col-4">
            <div className="form-check form-check-inline">
              <input
                className="form-check-input"
                name="plotH1"
                type="checkbox"
                checked={this.state.plotH1}
                onChange={this._onSetPlot}
              />
              <label className="form-check-label">Plot Carry</label>
            </div>
            <div className="form-check form-check-inline">
              <input
                className="form-check-input"
                name="plotH2"
                type="checkbox"
                checked={this.state.plotH2}
                onChange={this._onSetPlot}
              />
              <label className="form-check-label">Plot Mid</label>
            </div>
            <div className="form-check form-check-inline">
              <input
                className="form-check-input"
                name="plotH3"
                type="checkbox"
                checked={this.state.plotH3}
                onChange={this._onSetPlot}
              />
              <label className="form-check-label">Plot Offlane</label>
            </div>
            <div className="form-check form-check-inline">
              <input
                className="form-check-input"
                name="plotH4"
                type="checkbox"
                checked={this.state.plotH4}
                onChange={this._onSetPlot}
              />
              <label className="form-check-label">Plot Support [4]</label>
            </div>
            <div className="form-check form-check-inline">
              <input
                className="form-check-input"
                name="plotH5"
                type="checkbox"
                checked={this.state.plotH5}
                onChange={this._onSetPlot}
              />
              <label className="form-check-label">Plot Support [5]</label>
            </div>
          </div>

          <div className="col-2 text-center">
            <button
              className="btn btn-primary"
              type="submit"
              disabled={this.state.isSubmitDisabled}
              onClick={this._onPSubmit}
            >
              Query
            </button>
          </div>
        </div>

        {this.state.pdata !== null ? (
          <Plot
            data={this.state.pdata}
            style={{ width: "100%", height: "720px" }}
            layout={{
              autosize: true
            }}
            config={{ modeBarButtonsToRemove: ["sendDataToCloud"] }}
          />
        ) : null}
      </div>
    );
  }
}

export default DraftLineupParcat;
