import { Base64 } from "js-base64";
import { ApiSetting } from "./ApiSetting";
import * as queryString from "query-string";

export default class ApiResource {
  static _request(method, path, data, auth) {
    let fullPath =
      ApiSetting.DEFAULT_HOST + ApiSetting.DEFAULT_BASE_PATH + path;

    if (ApiSetting.USE_FETCH) {
      // TODO: Fill this out for fetch
    } else {
      return new Promise((resolve, reject) => {
        let xhr = new XMLHttpRequest();
        xhr.open(method, fullPath);
        // Set CORS pre-flight headers
        xhr.setRequestHeader("X-Requested-With", "XMLHttpRequest");
        if (auth !== undefined && auth !== null) {
          xhr.setRequestHeader(
            "Authorization",
            `Basic ${Base64.encode(
              decodeURIComponent(encodeURIComponent(auth))
            )}`
          );
        } else {
          if (ApiSetting.API_KEY !== null) {
            xhr.setRequestHeader("Authorization", `JWT ${ApiSetting.API_KEY}`);
          }
        }
        // Set headers
        xhr.setRequestHeader("Accept", "application/json; charset=utf-8");
        xhr.responseType = "text";
        xhr.onload = () => {
          if (xhr.status === 200) {
            resolve(JSON.parse(xhr.responseText));
          } else {
            reject(xhr);
          }
        };
        xhr.onerror = () => {
          reject(xhr);
        };
        // Send request
        if (data !== undefined && data !== null) {
          xhr.setRequestHeader(
            "Content-Type",
            "application/json; charset=utf-8"
          );
          xhr.send(data);
        } else {
          xhr.send();
        }
      });
    }
  }

  static _buildQueryString(parameters) {
    return queryString.stringify(parameters);
  }
}
