import ApiResource from "./ApiResource";

export default class DraftResource extends ApiResource {
  static async getDraft(h1, h2, h3, h4, h5) {
    const query_string = ApiResource._buildQueryString({
      1: h1,
      2: h2,
      3: h3,
      4: h4,
      5: h5
    });
    return await ApiResource._request("GET", `/draft?${query_string}`, null);
  }

  static async getDraftLineup(
    h1,
    h2,
    h3,
    h4,
    h5,
    threshold,
    csthreshold,
    page,
    page_size = 20,
    sort = [],
    tags = null
  ) {
    let query_params = {
      1: h1,
      2: h2,
      3: h3,
      4: h4,
      5: h5,
      t: threshold,
      cs: csthreshold,
      page: page,
      page_size: page_size
    };
    if (sort.length > 0) {
      sort.forEach(s => {
        query_params[s.id] = s.value;
      });
    }
    if (tags !== null) {
      Object.keys(tags).forEach(t => {
        query_params[t] = tags[t];
      });
    }
    const query_string = ApiResource._buildQueryString(query_params);
    return await ApiResource._request(
      "GET",
      `/draft/lineup?${query_string}`,
      null
    );
  }

  static async postDraftLineup(h1, h2, h3, h4, h5) {
    const query_string = ApiResource._buildQueryString({
      1: h1,
      2: h2,
      3: h3,
      4: h4,
      5: h5
    });
    return await ApiResource._request(
      "POST",
      `/draft/lineup?${query_string}`,
      null
    );
  }

  static async getDraftLineupParcat(
    h1,
    h2,
    h3,
    h4,
    h5,
    threshold,
    csthreshold,
    tags = null
  ) {
    let query_params = {
      1: h1,
      2: h2,
      3: h3,
      4: h4,
      5: h5,
      t: threshold,
      cs: csthreshold
    };
    if (tags !== null) {
      Object.keys(tags).forEach(t => {
        query_params[t] = tags[t];
      });
    }
    const query_string = ApiResource._buildQueryString(query_params);
    return await ApiResource._request(
      "GET",
      `/draft/lineup/parcat?${query_string}`,
      null
    );
  }

  static async getDraftLineupRollup(
    p1,
    p2,
    h1,
    h2,
    h3,
    h4,
    h5,
    threshold,
    csthreshold,
    tags = null
  ) {
    let query_params = {
      1: h1,
      2: h2,
      3: h3,
      4: h4,
      5: h5,
      t: threshold,
      cs: csthreshold
    };
    if (tags !== null) {
      Object.keys(tags).forEach(t => {
        query_params[t] = tags[t];
      });
    }
    const query_string = ApiResource._buildQueryString(query_params);
    return await ApiResource._request(
      "GET",
      `/draft/lineup/rollup/${p1}/${p2}?${query_string}`,
      null
    );
  }

  static async getDraftMatchup(h) {
    const query_string = ApiResource._buildQueryString({ h: h });
    return await ApiResource._request(
      "GET",
      `/draft/matchup?${query_string}`,
      null
    );
  }

  static async getDraftLastPick(p1, p2, p3, p4, p5, d1, d2, d3, d4, d5, ban) {
    const query_string = ApiResource._buildQueryString({
      p1: p1,
      p2: p2,
      p3: p3,
      p4: p4,
      p5: p5,
      d1: d1,
      d2: d2,
      d3: d3,
      d4: d4,
      d5: d5,
      ban: ban
    });
    return await ApiResource._request(
      "GET",
      `/draft/last_pick?${query_string}`,
      null
    );
  }
}
