import React, { Component } from "react";
import Select from "react-select";
import * as queryString from "query-string";
import { HERO_SELECT_OPTIONS } from "../../utils/HeroUtils";
import { formatOptionLabel } from "../../utils/SelectUtils";

const getHeroIdsFromQueryParams = params => {
  if (typeof params === "string") {
    return [parseInt(params)];
  } else if (params.constructor === Array) {
    return params.map(p => {
      return parseInt(p);
    });
  }
};

const getHeroSelectFromQueryParams = params => {
  if (typeof params === "string") {
    const id = parseInt(params);
    return HERO_SELECT_OPTIONS.filter(h => {
      return h.value === id;
    });
  } else if (params.constructor === Array) {
    return params.map(p => {
      const id = parseInt(p);
      return HERO_SELECT_OPTIONS.filter(h => {
        return h.value === id;
      })[0];
    });
  }
};

class DraftPregen extends Component {
  constructor(props) {
    super(props);
    const defaultHeroValues = queryString.parse(this.props.location.search);
    this.state = {
      h1:
        "1" in defaultHeroValues
          ? getHeroIdsFromQueryParams(defaultHeroValues["1"])
          : [],
      h2:
        "2" in defaultHeroValues
          ? getHeroIdsFromQueryParams(defaultHeroValues["2"])
          : [],
      h3:
        "3" in defaultHeroValues
          ? getHeroIdsFromQueryParams(defaultHeroValues["3"])
          : [],
      h4:
        "4" in defaultHeroValues
          ? getHeroIdsFromQueryParams(defaultHeroValues["4"])
          : [],
      h5:
        "5" in defaultHeroValues
          ? getHeroIdsFromQueryParams(defaultHeroValues["5"])
          : [],
      defaultH1:
        "1" in defaultHeroValues
          ? getHeroSelectFromQueryParams(defaultHeroValues["1"])
          : [],
      defaultH2:
        "2" in defaultHeroValues
          ? getHeroSelectFromQueryParams(defaultHeroValues["2"])
          : [],
      defaultH3:
        "3" in defaultHeroValues
          ? getHeroSelectFromQueryParams(defaultHeroValues["3"])
          : [],
      defaultH4:
        "4" in defaultHeroValues
          ? getHeroSelectFromQueryParams(defaultHeroValues["4"])
          : [],
      defaultH5:
        "5" in defaultHeroValues
          ? getHeroSelectFromQueryParams(defaultHeroValues["5"])
          : [],
      isSubmitDisabled: !(
        "1" in defaultHeroValues &&
        "2" in defaultHeroValues &&
        "3" in defaultHeroValues &&
        "4" in defaultHeroValues &&
        "5" in defaultHeroValues
      ),
      dataUri: null
    };
    this._onSelectH1 = this._onSelectH1.bind(this);
    this._onSelectH2 = this._onSelectH2.bind(this);
    this._onSelectH3 = this._onSelectH3.bind(this);
    this._onSelectH4 = this._onSelectH4.bind(this);
    this._onSelectH5 = this._onSelectH5.bind(this);
    this._onSubmit = this._onSubmit.bind(this);
    this.updateQueryParams = this.updateQueryParams.bind(this);
  }

  static __isSubmitDisabled(h1, h2, h3, h4, h5) {
    return ![h1, h2, h3, h4, h5].every(h => {
      return h.length > 0;
    });
  }

  _onSelectH1(options) {
    const value = options.map(o => {
      return o.value;
    });
    this.setState({
      h1: value,
      isSubmitDisabled: DraftPregen.__isSubmitDisabled(
        options,
        this.state.h2,
        this.state.h3,
        this.state.h4,
        this.state.h5
      )
    });
    this.updateQueryParams(
      value,
      this.state.h2,
      this.state.h3,
      this.state.h4,
      this.state.h5
    );
  }

  _onSelectH2(options) {
    const value = options.map(o => {
      return o.value;
    });
    this.setState({
      h2: value,
      isSubmitDisabled: DraftPregen.__isSubmitDisabled(
        this.state.h1,
        options,
        this.state.h3,
        this.state.h4,
        this.state.h5
      )
    });
    this.updateQueryParams(
      this.state.h1,
      value,
      this.state.h3,
      this.state.h4,
      this.state.h5
    );
  }

  _onSelectH3(options) {
    const value = options.map(o => {
      return o.value;
    });
    this.setState({
      h3: value,
      isSubmitDisabled: DraftPregen.__isSubmitDisabled(
        this.state.h1,
        this.state.h2,
        options,
        this.state.h4,
        this.state.h5
      )
    });
    this.updateQueryParams(
      this.state.h1,
      this.state.h2,
      value,
      this.state.h4,
      this.state.h5
    );
  }

  _onSelectH4(options) {
    const value = options.map(o => {
      return o.value;
    });
    this.setState({
      h4: value,
      isSubmitDisabled: DraftPregen.__isSubmitDisabled(
        this.state.h1,
        this.state.h2,
        this.state.h3,
        options,
        this.state.h5
      )
    });
    this.updateQueryParams(
      this.state.h1,
      this.state.h2,
      this.state.h3,
      value,
      this.state.h5
    );
  }

  _onSelectH5(options) {
    const value = options.map(o => {
      return o.value;
    });
    this.setState({
      h5: value,
      isSubmitDisabled: DraftPregen.__isSubmitDisabled(
        this.state.h1,
        this.state.h2,
        this.state.h3,
        this.state.h4,
        options
      )
    });
    this.updateQueryParams(
      this.state.h1,
      this.state.h2,
      this.state.h3,
      this.state.h4,
      value
    );
  }

  async _onSubmit() {
    this.setState({
      isSubmitDisabled: true
    });
    try {
      const data = JSON.stringify({
        h_0: this.state.h1,
        h_1: this.state.h2,
        h_2: this.state.h3,
        h_3: this.state.h4,
        h_4: this.state.h5
      });
      this.setState({
        dataUri: `data:application/json;charset=utf-8,${data}`,
        isSubmitDisabled: false
      });
    } catch (err) {
      this.setState({ isSubmitDisabled: false });
    }
  }

  updateQueryParams(h1, h2, h3, h4, h5) {
    this.props.history.push({
      pathname: this.props.history.location.pathname,
      search:
        "?" +
        queryString.stringify({
          1: h1,
          2: h2,
          3: h3,
          4: h4,
          5: h5
        })
    });
  }

  render() {
    return (
      <div className="container-fluid">
        <div className="pt-5 d-flex align-items-center">
          <div className="col-10">
            <Select
              options={HERO_SELECT_OPTIONS}
              defaultValue={this.state.defaultH1}
              formatOptionLabel={formatOptionLabel}
              placeholder={"Select Carry"}
              onChange={this._onSelectH1}
              isMulti
              isSearchable
            />

            <Select
              options={HERO_SELECT_OPTIONS}
              defaultValue={this.state.defaultH2}
              formatOptionLabel={formatOptionLabel}
              placeholder={"Select Mid"}
              onChange={this._onSelectH2}
              isMulti
              isSearchable
            />

            <Select
              options={HERO_SELECT_OPTIONS}
              defaultValue={this.state.defaultH3}
              formatOptionLabel={formatOptionLabel}
              placeholder={"Select Offlane"}
              onChange={this._onSelectH3}
              isMulti
              isSearchable
            />

            <Select
              options={HERO_SELECT_OPTIONS}
              defaultValue={this.state.defaultH4}
              formatOptionLabel={formatOptionLabel}
              placeholder={"Select Support (4)"}
              onChange={this._onSelectH4}
              isMulti
              isSearchable
            />

            <Select
              options={HERO_SELECT_OPTIONS}
              defaultValue={this.state.defaultH5}
              formatOptionLabel={formatOptionLabel}
              placeholder={"Select Support (5)"}
              onChange={this._onSelectH5}
              isMulti
              isSearchable
            />
          </div>

          <div className="col-2 text-center">
            <button
              className="btn btn-primary"
              type="submit"
              disabled={this.state.isSubmitDisabled}
              onClick={this._onSubmit}
            >
              Generate
            </button>
          </div>
        </div>

        {this.state.dataUri !== undefined && this.state.dataUri !== null ? (
          <div className="pt-5 d-flex align-items-center">
            <div className="col-10 text-center">
              <a
                className="btn btn-primary"
                href={this.state.dataUri}
                download="pregen.json"
              >
                Click here to download
              </a>
            </div>
          </div>
        ) : null}
      </div>
    );
  }
}

export default DraftPregen;
