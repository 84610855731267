import React, { Component } from "react";
import Select from "react-select";
import ReactTable from "react-table";
import "react-table/react-table.css";
import Slider from "rc-slider";
import "rc-slider/assets/index.css";
import * as queryString from "query-string";
import _ from "lodash";
import DraftResource from "../../resources/DraftResource";
import {
  getHeroIdsFromQueryParams,
  getHeroSelectFromQueryParams,
  getTagSelectFromQueryParams,
  getTagsFromQueryParams,
  HERO_SELECT_OPTIONS,
  HERO_TAG_OPTIONS,
  POSITION_SELECT_OPTIONS
} from "../../utils/HeroUtils";
import {
  filterHeroCell,
  formatHeroCell,
  formatPivotHeroCell
} from "../../utils/TableUtils";
import { formatOptionLabel } from "../../utils/SelectUtils";

const SliderWithToolTip = Slider.createSliderWithTooltip(Slider);

const getNameFromValue = params => {
  switch (params) {
    case "0":
      return "Carry";
    case "1":
      return "Mid";
    case "2":
      return "Offlane";
    case "3":
      return "Support (4)";
    case "4":
      return "Support (5)";
    default:
      return "";
  }
};

class DraftLineupRollup extends Component {
  constructor(props) {
    super(props);
    const qs = queryString.parse(this.props.location.search);
    this.state = {
      h1: "1" in qs ? getHeroIdsFromQueryParams(qs["1"]) : [],
      h2: "2" in qs ? getHeroIdsFromQueryParams(qs["2"]) : [],
      h3: "3" in qs ? getHeroIdsFromQueryParams(qs["3"]) : [],
      h4: "4" in qs ? getHeroIdsFromQueryParams(qs["4"]) : [],
      h5: "5" in qs ? getHeroIdsFromQueryParams(qs["5"]) : [],
      defaultH1: "1" in qs ? getHeroSelectFromQueryParams(qs["1"]) : [],
      defaultH2: "2" in qs ? getHeroSelectFromQueryParams(qs["2"]) : [],
      defaultH3: "3" in qs ? getHeroSelectFromQueryParams(qs["3"]) : [],
      defaultH4: "4" in qs ? getHeroSelectFromQueryParams(qs["4"]) : [],
      defaultH5: "5" in qs ? getHeroSelectFromQueryParams(qs["5"]) : [],
      job: null,
      jobInterval: null,
      isSubmitDisabled: !(
        "1" in qs &&
        "2" in qs &&
        "3" in qs &&
        "4" in qs &&
        "5" in qs
      ),
      isLineupEnabled: false,
      lineup: [],
      lineupPages: null,
      isLineupLoading: false,
      p1: null,
      p2: null,
      pthreshold: 0.7,
      csthreshold: 5.0,
      tags: getTagsFromQueryParams(qs),
      defaultT1: "t1" in qs ? getTagSelectFromQueryParams(qs["t1"]) : [],
      defaultT2: "t2" in qs ? getTagSelectFromQueryParams(qs["t2"]) : [],
      defaultT3: "t3" in qs ? getTagSelectFromQueryParams(qs["t3"]) : [],
      defaultT4: "t4" in qs ? getTagSelectFromQueryParams(qs["t4"]) : [],
      defaultT5: "t5" in qs ? getTagSelectFromQueryParams(qs["t5"]) : [],
      defaultTT: "tt" in qs ? getTagSelectFromQueryParams(qs["tt"]) : [],
      pdata: null,
      isPSubmitDisabled: true
    };
    this._onSelectH1 = this._onSelectH1.bind(this);
    this._onSelectH2 = this._onSelectH2.bind(this);
    this._onSelectH3 = this._onSelectH3.bind(this);
    this._onSelectH4 = this._onSelectH4.bind(this);
    this._onSelectH5 = this._onSelectH5.bind(this);
    this._onSelectT1 = this._onSelectT1.bind(this);
    this._onSelectT2 = this._onSelectT2.bind(this);
    this._onSelectT3 = this._onSelectT3.bind(this);
    this._onSelectT4 = this._onSelectT4.bind(this);
    this._onSelectT5 = this._onSelectT5.bind(this);
    this._onSelectTT = this._onSelectTT.bind(this);
    this.updateQueryParams = this.updateQueryParams.bind(this);
    this._onSelectP1 = this._onSelectP1.bind(this);
    this._onSelectP2 = this._onSelectP2.bind(this);
    this._onSetPthreshold = this._onSetPthreshold.bind(this);
    this._onSetCsthreshold = this._onSetCsthreshold.bind(this);
    this._onPSubmit = this._onPSubmit.bind(this);
  }

  static __isSubmitDisabled(h1, h2, h3, h4, h5) {
    return ![h1, h2, h3, h4, h5].every(h => {
      return h.length > 0;
    });
  }

  _onSelectH1(options) {
    const value = options.map(o => {
      return o.value;
    });
    this.setState({
      h1: value,
      isSubmitDisabled: DraftLineupRollup.__isSubmitDisabled(
        options,
        this.state.h2,
        this.state.h3,
        this.state.h4,
        this.state.h5
      )
    });
    this.updateQueryParams(
      value,
      this.state.h2,
      this.state.h3,
      this.state.h4,
      this.state.h5,
      this.state.tags
    );
  }

  _onSelectH2(options) {
    const value = options.map(o => {
      return o.value;
    });
    this.setState({
      h2: value,
      isSubmitDisabled: DraftLineupRollup.__isSubmitDisabled(
        this.state.h1,
        options,
        this.state.h3,
        this.state.h4,
        this.state.h5
      )
    });
    this.updateQueryParams(
      this.state.h1,
      value,
      this.state.h3,
      this.state.h4,
      this.state.h5,
      this.state.tags
    );
  }

  _onSelectH3(options) {
    const value = options.map(o => {
      return o.value;
    });
    this.setState({
      h3: value,
      isSubmitDisabled: DraftLineupRollup.__isSubmitDisabled(
        this.state.h1,
        this.state.h2,
        options,
        this.state.h4,
        this.state.h5
      )
    });
    this.updateQueryParams(
      this.state.h1,
      this.state.h2,
      value,
      this.state.h4,
      this.state.h5,
      this.state.tags
    );
  }

  _onSelectH4(options) {
    const value = options.map(o => {
      return o.value;
    });
    this.setState({
      h4: value,
      isSubmitDisabled: DraftLineupRollup.__isSubmitDisabled(
        this.state.h1,
        this.state.h2,
        this.state.h3,
        options,
        this.state.h5
      )
    });
    this.updateQueryParams(
      this.state.h1,
      this.state.h2,
      this.state.h3,
      value,
      this.state.h5,
      this.state.tags
    );
  }

  _onSelectH5(options) {
    const value = options.map(o => {
      return o.value;
    });
    this.setState({
      h5: value,
      isSubmitDisabled: DraftLineupRollup.__isSubmitDisabled(
        this.state.h1,
        this.state.h2,
        this.state.h3,
        this.state.h4,
        options
      )
    });
    this.updateQueryParams(
      this.state.h1,
      this.state.h2,
      this.state.h3,
      this.state.h4,
      value,
      this.state.tags
    );
  }

  _onSelectT1(options) {
    const value = options.map(o => {
      return o.value;
    });
    let tags = this.state.tags;
    if (value.length > 0) {
      tags["t1"] = value;
    } else {
      delete tags["t1"];
    }
    this.setState({
      tags: tags
    });
    this.updateQueryParams(
      this.state.h1,
      this.state.h2,
      this.state.h3,
      this.state.h4,
      this.state.h5,
      tags
    );
  }

  _onSelectT2(options) {
    const value = options.map(o => {
      return o.value;
    });
    let tags = this.state.tags;
    if (value.length > 0) {
      tags["t2"] = value;
    } else {
      delete tags["t2"];
    }
    this.setState({
      tags: tags
    });
    this.updateQueryParams(
      this.state.h1,
      this.state.h2,
      this.state.h3,
      this.state.h4,
      this.state.h5,
      tags
    );
  }

  _onSelectT3(options) {
    const value = options.map(o => {
      return o.value;
    });
    let tags = this.state.tags;
    if (value.length > 0) {
      tags["t3"] = value;
    } else {
      delete tags["t3"];
    }
    this.setState({
      tags: tags
    });
    this.updateQueryParams(
      this.state.h1,
      this.state.h2,
      this.state.h3,
      this.state.h4,
      this.state.h5,
      tags
    );
  }

  _onSelectT4(options) {
    const value = options.map(o => {
      return o.value;
    });
    let tags = this.state.tags;
    if (value.length > 0) {
      tags["t4"] = value;
    } else {
      delete tags["t4"];
    }
    this.setState({
      tags: tags
    });
    this.updateQueryParams(
      this.state.h1,
      this.state.h2,
      this.state.h3,
      this.state.h4,
      this.state.h5,
      tags
    );
  }

  _onSelectT5(options) {
    const value = options.map(o => {
      return o.value;
    });
    let tags = this.state.tags;
    if (value.length > 0) {
      tags["t5"] = value;
    } else {
      delete tags["t5"];
    }
    this.setState({
      tags: tags
    });
    this.updateQueryParams(
      this.state.h1,
      this.state.h2,
      this.state.h3,
      this.state.h4,
      this.state.h5,
      tags
    );
  }

  _onSelectTT(options) {
    const value = options.map(o => {
      return o.value;
    });
    let tags = this.state.tags;
    if (value.length > 0) {
      tags["tt"] = value;
    } else {
      delete tags["tt"];
    }
    this.setState({
      tags: tags
    });
    this.updateQueryParams(
      this.state.h1,
      this.state.h2,
      this.state.h3,
      this.state.h4,
      this.state.h5,
      tags
    );
  }

  updateQueryParams(h1, h2, h3, h4, h5, tags) {
    this.props.history.push({
      pathname: this.props.history.location.pathname,
      search:
        "?" +
        queryString.stringify({
          1: h1,
          2: h2,
          3: h3,
          4: h4,
          5: h5,
          t1: tags["t1"],
          t2: tags["t2"],
          t3: tags["t3"],
          t4: tags["t4"],
          t5: tags["t5"],
          tt: tags["tt"]
        })
    });
  }

  _onSelectP1(options) {
    this.setState({
      p1: options.value,
      isPSubmitDisabled: !(
        this.state.p2 !== null &&
        this.state.p2 !== undefined &&
        this.state.p2 !== options.value
      )
    });
  }

  _onSelectP2(options) {
    this.setState({
      p2: options.value,
      isPSubmitDisabled: !(
        this.state.p1 !== null &&
        this.state.p1 !== undefined &&
        this.state.p1 !== options.value
      )
    });
  }

  _onSetPthreshold(value) {
    this.setState({
      pthreshold: value
    });
  }

  _onSetCsthreshold(value) {
    this.setState({
      csthreshold: value
    });
  }

  async _onPSubmit() {
    this.setState({
      isPSubmitDisabled: true
    });
    // Apply tags
    const tags =
      Object.keys(this.state.tags).length > 0 ? this.state.tags : null;

    try {
      const data = await DraftResource.getDraftLineupRollup(
        this.state.p1,
        this.state.p2,
        this.state.h1,
        this.state.h2,
        this.state.h3,
        this.state.h4,
        this.state.h5,
        this.state.pthreshold,
        this.state.csthreshold,
        tags
      );
      this.setState({
        pdata: data
      });
    } catch (e) {
      // Do nothing
    }

    this.setState({
      isPSubmitDisabled: false
    });
  }

  render() {
    let availablePositions = new Set(["0", "1", "2", "3", "4"]);
    availablePositions.delete(this.state.p1);
    availablePositions.delete(this.state.p2);
    let heroPoolPositions = Array.from(availablePositions);
    heroPoolPositions.sort((a, b) => {
      return a - b;
    });

    return (
      <div className="container-fluid">
        <div className="pt-5 d-flex align-items-center">
          <div className="col-8">
            <Select
              options={HERO_SELECT_OPTIONS}
              defaultValue={this.state.defaultH1}
              formatOptionLabel={formatOptionLabel}
              placeholder={"Select Carry"}
              onChange={this._onSelectH1}
              isMulti
              isSearchable
            />

            <Select
              options={HERO_SELECT_OPTIONS}
              defaultValue={this.state.defaultH2}
              formatOptionLabel={formatOptionLabel}
              placeholder={"Select Mid"}
              onChange={this._onSelectH2}
              isMulti
              isSearchable
            />

            <Select
              options={HERO_SELECT_OPTIONS}
              defaultValue={this.state.defaultH3}
              formatOptionLabel={formatOptionLabel}
              placeholder={"Select Offlane"}
              onChange={this._onSelectH3}
              isMulti
              isSearchable
            />

            <Select
              options={HERO_SELECT_OPTIONS}
              defaultValue={this.state.defaultH4}
              formatOptionLabel={formatOptionLabel}
              placeholder={"Select Support (4)"}
              onChange={this._onSelectH4}
              isMulti
              isSearchable
            />

            <Select
              options={HERO_SELECT_OPTIONS}
              defaultValue={this.state.defaultH5}
              formatOptionLabel={formatOptionLabel}
              placeholder={"Select Support (5)"}
              onChange={this._onSelectH5}
              isMulti
              isSearchable
            />
          </div>

          <div className="col-4">
            <Select
              options={HERO_TAG_OPTIONS}
              defaultValue={this.state.defaultT1}
              placeholder={"Select Carry Tags"}
              onChange={this._onSelectT1}
              isMulti
              isSearchable
            />

            <Select
              options={HERO_TAG_OPTIONS}
              defaultValue={this.state.defaultT2}
              placeholder={"Select Mid Tags"}
              onChange={this._onSelectT2}
              isMulti
              isSearchable
            />

            <Select
              options={HERO_TAG_OPTIONS}
              defaultValue={this.state.defaultT3}
              placeholder={"Select Offlane Tags"}
              onChange={this._onSelectT3}
              isMulti
              isSearchable
            />

            <Select
              options={HERO_TAG_OPTIONS}
              defaultValue={this.state.defaultT4}
              placeholder={"Select Support(4) Tags"}
              onChange={this._onSelectT4}
              isMulti
              isSearchable
            />

            <Select
              options={HERO_TAG_OPTIONS}
              defaultValue={this.state.defaultT5}
              placeholder={"Select Support(5) Tags"}
              onChange={this._onSelectT5}
              isMulti
              isSearchable
            />
          </div>
        </div>

        <div className="pt-2 d-flex align-items-center">
          <div className="col-12">
            <Select
              options={HERO_TAG_OPTIONS}
              defaultValue={this.state.defaultTT}
              placeholder={"Select Team Tags"}
              onChange={this._onSelectTT}
              isMulti
              isSearchable
            />
          </div>
        </div>

        <div className="pt-2 pb-2 d-flex align-items-center">
          <div className="col-2">
            <Select
              options={POSITION_SELECT_OPTIONS}
              placeholder={"Select Pivot 1"}
              onChange={this._onSelectP1}
            />
          </div>

          <div className="col-2">
            <Select
              options={POSITION_SELECT_OPTIONS}
              placeholder={"Select Pivot 2"}
              onChange={this._onSelectP2}
            />
          </div>

          <div className="col-4">
            <SliderWithToolTip
              min={0}
              max={1}
              step={0.01}
              value={this.state.pthreshold}
              tipFormatter={value => `${value * 100}% minimum`}
              onChange={this._onSetPthreshold}
            />
          </div>

          <div className="col-2">
            <SliderWithToolTip
              min={0}
              max={7}
              step={0.1}
              value={this.state.csthreshold}
              tipFormatter={value =>
                `${value} minimum [equivalent of ${Math.pow(
                  30,
                  value / 5
                )} games played per player]`
              }
              onChange={this._onSetCsthreshold}
            />
          </div>

          <div className="col-2 text-center">
            <button
              className="btn btn-primary"
              type="submit"
              disabled={
                this.state.isSubmitDisabled || this.state.isPSubmitDisabled
              }
              onClick={this._onPSubmit}
            >
              Query
            </button>
          </div>
        </div>

        {this.state.pdata !== null ? (
          <ReactTable
            data={this.state.pdata.rows}
            columns={[
              {
                Header: getNameFromValue(this.state.p1),
                accessor: "h0",
                PivotValue: formatHeroCell,
                filterable: true,
                filterMethod: filterHeroCell
              },
              {
                Header: getNameFromValue(this.state.p2),
                accessor: "h1",
                Cell: formatPivotHeroCell,
                aggregate: vals => vals
              },
              {
                Header: "Win %",
                accessor: "win_probability",
                aggregate: vals => _.mean(vals)
              },
              {
                Header: "Comfort Score",
                accessor: "comfort_score",
                aggregate: vals => _.mean(vals)
              },
              {
                Header: "Count",
                accessor: "count",
                aggregate: vals => _.sum(vals)
              },
              {
                Header: getNameFromValue(heroPoolPositions[0]),
                accessor: "hero_pools[0]",
                Cell: formatPivotHeroCell,
                aggregate: vals => {
                  let values = _.flattenDeep(vals);
                  values.sort((a, b) => {
                    return a - b;
                  });
                  return _.sortedUniq(values);
                }
              },
              {
                Header: getNameFromValue(heroPoolPositions[1]),
                accessor: "hero_pools[1]",
                Cell: formatPivotHeroCell,
                aggregate: vals => {
                  let values = _.flattenDeep(vals);
                  values.sort((a, b) => {
                    return a - b;
                  });
                  return _.sortedUniq(values);
                }
              },
              {
                Header: getNameFromValue(heroPoolPositions[2]),
                accessor: "hero_pools[2]",
                Cell: formatPivotHeroCell,
                aggregate: vals => {
                  let values = _.flattenDeep(vals);
                  values.sort((a, b) => {
                    return a - b;
                  });
                  return _.sortedUniq(values);
                }
              }
            ]}
            pivotBy={["h0"]}
          />
        ) : null}
      </div>
    );
  }
}

export default DraftLineupRollup;
