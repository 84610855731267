import React, { Component } from "react";
import Plot from "react-plotly.js";

const COLOR_POS = {
  "0": "#3569fe",
  "1": "#6ef3e9",
  "2": "#9950ea",
  "3": "#f7fe35",
  "4": "#ff8b4c"
};

class TimeseriesGraph extends Component {
  render() {
    const { timeseries, property_key, range } = this.props;
    return (
      <Plot
        data={timeseries.map(t => {
          return {
            x: Object.keys(t.t).map(ts => {
              return parseInt(ts) / 60;
            }),
            low: Object.keys(t.t).map(ts => {
              return t.t[ts][property_key][0];
            }),
            close: Object.keys(t.t).map(ts => {
              return t.t[ts][property_key][1];
            }),
            open: Object.keys(t.t).map(ts => {
              return t.t[ts][property_key][3];
            }),
            high: Object.keys(t.t).map(ts => {
              return t.t[ts][property_key][4];
            }),
            type: "candlestick",
            xaxis: "x",
            yaxis: "y",
            name: `${property_key} [${t.p}]`,
            increasing: { line: { color: COLOR_POS[t.p] } },
            decreasing: { line: { color: COLOR_POS[t.p] } }
          };
        })}
        style={{ width: "100%", height: "100%" }}
        layout={{
          autosize: true,
          xaxis: {
            rangeslider: {
              visible: false
            }
          },
          yaxis: {
            range: range
          }
        }}
        config={{ modeBarButtonsToRemove: ["sendDataToCloud"] }}
      />
    );
  }
}

export default TimeseriesGraph;
