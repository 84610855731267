import React, { Component } from "react";
import Select from "react-select";
import hero_metadata from "../../assets/hero_metadata";
import DraftResource from "../../resources/DraftResource";
import { HERO_SELECT_OPTIONS } from "../../utils/HeroUtils";
import { formatOptionLabel } from "../../utils/SelectUtils";
import MatchUpTable from "../../components/MatchUpTable";
import WinProbByDurationGraph from "../../components/WinProbByDurationGraph";

class Draft extends Component {
  constructor(props) {
    super(props);
    this.state = {
      h1: null,
      h2: null,
      h3: null,
      h4: null,
      h5: null,
      win_probability: null,
      duration_win_probability: null,
      matchups: null,
      isSubmitDisabled: true
    };
    this._onSelectH1 = this._onSelectH1.bind(this);
    this._onSelectH2 = this._onSelectH2.bind(this);
    this._onSelectH3 = this._onSelectH3.bind(this);
    this._onSelectH4 = this._onSelectH4.bind(this);
    this._onSelectH5 = this._onSelectH5.bind(this);
    this._onSubmit = this._onSubmit.bind(this);
  }

  static __isSubmitDisabled(h1, h2, h3, h4, h5) {
    return ![h1, h2, h3, h4, h5].every(h => {
      return h !== null;
    });
  }

  _onSelectH1(options) {
    this.setState({
      h1: options.value,
      isSubmitDisabled: Draft.__isSubmitDisabled(
        options.value,
        this.state.h2,
        this.state.h3,
        this.state.h4,
        this.state.h5
      )
    });
  }

  _onSelectH2(options) {
    this.setState({
      h2: options.value,
      isSubmitDisabled: Draft.__isSubmitDisabled(
        this.state.h1,
        options.value,
        this.state.h3,
        this.state.h4,
        this.state.h5
      )
    });
  }

  _onSelectH3(options) {
    this.setState({
      h3: options.value,
      isSubmitDisabled: Draft.__isSubmitDisabled(
        this.state.h1,
        this.state.h2,
        options.value,
        this.state.h4,
        this.state.h5
      )
    });
  }

  _onSelectH4(options) {
    this.setState({
      h4: options.value,
      isSubmitDisabled: Draft.__isSubmitDisabled(
        this.state.h1,
        this.state.h2,
        this.state.h3,
        options.value,
        this.state.h5
      )
    });
  }

  _onSelectH5(options) {
    this.setState({
      h5: options.value,
      isSubmitDisabled: Draft.__isSubmitDisabled(
        this.state.h1,
        this.state.h2,
        this.state.h3,
        this.state.h4,
        options.value
      )
    });
  }

  async _onSubmit() {
    this.setState({
      isSubmitDisabled: true
    });
    try {
      const data = await DraftResource.getDraft(
        this.state.h1,
        this.state.h2,
        this.state.h3,
        this.state.h4,
        this.state.h5
      );
      const matchups = hero_metadata.map(hero => {
        return {
          id: hero["id"],
          win_probability: data.matchups[hero["id"]]
        };
      });
      this.setState({
        win_probability: data.win_probability,
        duration_win_probability: data.duration_win_probability,
        matchups: matchups
      });
    } catch (err) {
      // Do nothing
    }

    this.setState({
      isSubmitDisabled: false
    });
  }

  render() {
    return (
      <div className="container-fluid">
        <div className="pt-5 d-flex align-items-center">
          <div className="col-10">
            <Select
              options={HERO_SELECT_OPTIONS}
              formatOptionLabel={formatOptionLabel}
              placeholder={"Select Carry"}
              onChange={this._onSelectH1}
              isSearchable
            />

            <Select
              options={HERO_SELECT_OPTIONS}
              formatOptionLabel={formatOptionLabel}
              placeholder={"Select Mid"}
              onChange={this._onSelectH2}
              isSearchable
            />

            <Select
              options={HERO_SELECT_OPTIONS}
              formatOptionLabel={formatOptionLabel}
              placeholder={"Select Offlane"}
              onChange={this._onSelectH3}
              isSearchable
            />

            <Select
              options={HERO_SELECT_OPTIONS}
              formatOptionLabel={formatOptionLabel}
              placeholder={"Select Support (4)"}
              onChange={this._onSelectH4}
              isSearchable
            />

            <Select
              options={HERO_SELECT_OPTIONS}
              formatOptionLabel={formatOptionLabel}
              placeholder={"Select Support (5)"}
              onChange={this._onSelectH5}
              isSearchable
            />
          </div>

          <div className="col-2 text-center">
            <button
              className="btn btn-primary"
              type="submit"
              disabled={this.state.isSubmitDisabled}
              onClick={this._onSubmit}
            >
              Generate
            </button>
          </div>
        </div>

        {this.state.win_probability !== null ? (
          <div className="pt-5 text-center">
            <h1>Win Probability: {this.state.win_probability["blended"]}</h1>
          </div>
        ) : null}

        {this.state.duration_win_probability !== null ? (
          <div className="pt-5">
            <WinProbByDurationGraph
              duration_win_probability={this.state.duration_win_probability}
            />
          </div>
        ) : null}

        {this.state.matchups !== null ? (
          <div className="pt-5">
            <MatchUpTable matchups={this.state.matchups} />
          </div>
        ) : null}
      </div>
    );
  }
}

export default Draft;
