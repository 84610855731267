import React, { Component } from "react";
import Select from "react-select";
import Plot from "react-plotly.js";
import DraftResource from "../../resources/DraftResource";
import { HERO_SELECT_OPTIONS } from "../../utils/HeroUtils";
import { formatOptionLabel } from "../../utils/SelectUtils";

class DraftMatch extends Component {
  constructor(props) {
    super(props);
    this.state = {
      r_h1: null,
      r_h2: null,
      r_h3: null,
      r_h4: null,
      r_h5: null,
      d_h1: null,
      d_h2: null,
      d_h3: null,
      d_h4: null,
      d_h5: null,
      r_data: null,
      d_data: null,
      r_win_probability: null,
      d_win_probability: null,
      t_probability: null,
      isSubmitDisabled: true
    };
    this._onSelectRH1 = this._onSelectRH1.bind(this);
    this._onSelectRH2 = this._onSelectRH2.bind(this);
    this._onSelectRH3 = this._onSelectRH3.bind(this);
    this._onSelectRH4 = this._onSelectRH4.bind(this);
    this._onSelectRH5 = this._onSelectRH5.bind(this);
    this._onSelectDH1 = this._onSelectDH1.bind(this);
    this._onSelectDH2 = this._onSelectDH2.bind(this);
    this._onSelectDH3 = this._onSelectDH3.bind(this);
    this._onSelectDH4 = this._onSelectDH4.bind(this);
    this._onSelectDH5 = this._onSelectDH5.bind(this);
    this._onSubmit = this._onSubmit.bind(this);
  }

  static __isSubmitDisabled(
    r_h1,
    r_h2,
    r_h3,
    r_h4,
    r_h5,
    d_h1,
    d_h2,
    d_h3,
    d_h4,
    d_h5
  ) {
    return ![r_h1, r_h2, r_h3, r_h4, r_h5, d_h1, d_h2, d_h3, d_h4, d_h5].every(
      h => {
        return h !== null;
      }
    );
  }

  _onSelectRH1(options) {
    this.setState({
      r_h1: options.value,
      isSubmitDisabled: DraftMatch.__isSubmitDisabled(
        options.value,
        this.state.r_h2,
        this.state.r_h3,
        this.state.r_h4,
        this.state.r_h5,
        this.state.d_h1,
        this.state.d_h2,
        this.state.d_h3,
        this.state.d_h4,
        this.state.d_h5
      )
    });
  }

  _onSelectRH2(options) {
    this.setState({
      r_h2: options.value,
      isSubmitDisabled: DraftMatch.__isSubmitDisabled(
        this.state.r_h1,
        options.value,
        this.state.r_h3,
        this.state.r_h4,
        this.state.r_h5,
        this.state.d_h1,
        this.state.d_h2,
        this.state.d_h3,
        this.state.d_h4,
        this.state.d_h5
      )
    });
  }

  _onSelectRH3(options) {
    this.setState({
      r_h3: options.value,
      isSubmitDisabled: DraftMatch.__isSubmitDisabled(
        this.state.r_h1,
        this.state.r_h2,
        options.value,
        this.state.r_h4,
        this.state.r_h5,
        this.state.d_h1,
        this.state.d_h2,
        this.state.d_h3,
        this.state.d_h4,
        this.state.d_h5
      )
    });
  }

  _onSelectRH4(options) {
    this.setState({
      r_h4: options.value,
      isSubmitDisabled: DraftMatch.__isSubmitDisabled(
        this.state.r_h1,
        this.state.r_h2,
        this.state.r_h3,
        options.value,
        this.state.r_h5,
        this.state.d_h1,
        this.state.d_h2,
        this.state.d_h3,
        this.state.d_h4,
        this.state.d_h5
      )
    });
  }

  _onSelectRH5(options) {
    this.setState({
      r_h5: options.value,
      isSubmitDisabled: DraftMatch.__isSubmitDisabled(
        this.state.r_h1,
        this.state.r_h2,
        this.state.r_h3,
        this.state.r_h4,
        options.value,
        this.state.d_h1,
        this.state.d_h2,
        this.state.d_h3,
        this.state.d_h4,
        this.state.d_h5
      )
    });
  }

  _onSelectDH1(options) {
    this.setState({
      d_h1: options.value,
      isSubmitDisabled: DraftMatch.__isSubmitDisabled(
        this.state.r_h1,
        this.state.r_h2,
        this.state.r_h3,
        this.state.r_h4,
        this.state.r_h5,
        options.value,
        this.state.d_h2,
        this.state.d_h3,
        this.state.d_h4,
        this.state.d_h5
      )
    });
  }

  _onSelectDH2(options) {
    this.setState({
      d_h2: options.value,
      isSubmitDisabled: DraftMatch.__isSubmitDisabled(
        this.state.r_h1,
        this.state.r_h2,
        this.state.r_h3,
        this.state.r_h4,
        this.state.r_h5,
        this.state.d_h1,
        options.value,
        this.state.d_h3,
        this.state.d_h4,
        this.state.d_h5
      )
    });
  }

  _onSelectDH3(options) {
    this.setState({
      d_h3: options.value,
      isSubmitDisabled: DraftMatch.__isSubmitDisabled(
        this.state.r_h1,
        this.state.r_h2,
        this.state.r_h3,
        this.state.r_h4,
        this.state.r_h5,
        this.state.d_h1,
        this.state.d_h2,
        options.value,
        this.state.d_h4,
        this.state.d_h5
      )
    });
  }

  _onSelectDH4(options) {
    this.setState({
      d_h4: options.value,
      isSubmitDisabled: DraftMatch.__isSubmitDisabled(
        this.state.r_h1,
        this.state.r_h2,
        this.state.r_h3,
        this.state.r_h4,
        this.state.r_h5,
        this.state.d_h1,
        this.state.d_h2,
        this.state.d_h3,
        options.value,
        this.state.d_h5
      )
    });
  }

  _onSelectDH5(options) {
    this.setState({
      d_h5: options.value,
      isSubmitDisabled: DraftMatch.__isSubmitDisabled(
        this.state.r_h1,
        this.state.r_h2,
        this.state.r_h3,
        this.state.r_h4,
        this.state.r_h5,
        this.state.d_h1,
        this.state.d_h2,
        this.state.d_h3,
        this.state.d_h4,
        options.value
      )
    });
  }

  async _onSubmit() {
    this.setState({
      isSubmitDisabled: true
    });
    try {
      const r_data = await DraftResource.getDraft(
        this.state.r_h1,
        this.state.r_h2,
        this.state.r_h3,
        this.state.r_h4,
        this.state.r_h5
      );
      const d_data = await DraftResource.getDraft(
        this.state.d_h1,
        this.state.d_h2,
        this.state.d_h3,
        this.state.d_h4,
        this.state.d_h5
      );
      this.setState({
        r_data: r_data,
        d_data: d_data,
        r_win_probability: r_data.win_probability["blended"],
        d_win_probability: d_data.win_probability["blended"],
        t_probability:
          r_data.win_probability["blended"] + d_data.win_probability["blended"]
      });
    } catch (err) {
      // Do nothing
    }

    this.setState({
      isSubmitDisabled: false
    });
  }

  render() {
    return (
      <div className="container-fluid">
        <div className="pt-5 d-flex align-items-center">
          <div className="col-5">
            <Select
              options={HERO_SELECT_OPTIONS}
              formatOptionLabel={formatOptionLabel}
              placeholder={"Select Radiant Carry"}
              onChange={this._onSelectRH1}
              isSearchable
            />

            <Select
              options={HERO_SELECT_OPTIONS}
              formatOptionLabel={formatOptionLabel}
              placeholder={"Select Radiant Mid"}
              onChange={this._onSelectRH2}
              isSearchable
            />

            <Select
              options={HERO_SELECT_OPTIONS}
              formatOptionLabel={formatOptionLabel}
              placeholder={"Select Radiant Offlane"}
              onChange={this._onSelectRH3}
              isSearchable
            />

            <Select
              options={HERO_SELECT_OPTIONS}
              formatOptionLabel={formatOptionLabel}
              placeholder={"Select Radiant Support (4)"}
              onChange={this._onSelectRH4}
              isSearchable
            />

            <Select
              options={HERO_SELECT_OPTIONS}
              formatOptionLabel={formatOptionLabel}
              placeholder={"Select Radiant Support (5)"}
              onChange={this._onSelectRH5}
              isSearchable
            />
          </div>

          <div className="col-2 text-center">
            <button
                className="btn btn-primary"
              type="submit"
              disabled={this.state.isSubmitDisabled}
              onClick={this._onSubmit}
            >
              Generate
            </button>
          </div>

          <div className="col-5">
            <Select
              options={HERO_SELECT_OPTIONS}
              formatOptionLabel={formatOptionLabel}
              placeholder={"Select Dire Carry"}
              onChange={this._onSelectDH1}
              isSearchable
            />

            <Select
              options={HERO_SELECT_OPTIONS}
              formatOptionLabel={formatOptionLabel}
              placeholder={"Select Dire Mid"}
              onChange={this._onSelectDH2}
              isSearchable
            />

            <Select
              options={HERO_SELECT_OPTIONS}
              formatOptionLabel={formatOptionLabel}
              placeholder={"Select Dire Offlane"}
              onChange={this._onSelectDH3}
              isSearchable
            />

            <Select
              options={HERO_SELECT_OPTIONS}
              formatOptionLabel={formatOptionLabel}
              placeholder={"Select Dire Support (4)"}
              onChange={this._onSelectDH4}
              isSearchable
            />

            <Select
              options={HERO_SELECT_OPTIONS}
              formatOptionLabel={formatOptionLabel}
              placeholder={"Select Dire Support (5)"}
              onChange={this._onSelectDH5}
              isSearchable
            />
          </div>
        </div>

        {this.state.r_win_probability !== null &&
        this.state.d_win_probability !== null ? (
          <div className="pt-5 row">
            <div className="col-4 text-center">
              <h2>
                Radiant:{" "}
                {(
                  this.state.r_win_probability /
                  this.state.t_probability *
                  100
                ).toFixed(3)}%
              </h2>
            </div>

            <div className="col-4 text-center">
              <h2>
                Winner:{" "}
                {this.state.r_win_probability > this.state.d_win_probability
                  ? "Radiant"
                  : this.state.r_win_probability ===
                    this.state.d_win_probability
                    ? "Tie"
                    : "Dire"}
              </h2>
            </div>

            <div className="col-4 text-center">
              <h2>
                Dire:{" "}
                {(
                  this.state.d_win_probability /
                  this.state.t_probability *
                  100
                ).toFixed(3)}%
              </h2>
            </div>
          </div>
        ) : null}

        {this.state.r_data !== null && this.state.d_data !== null ? (
          <div className="pt-5">
            <Plot
              data={[
                {
                  x: this.state.r_data.duration_win_probability["q2"].map(x => {
                    return x.duration / 60;
                  }),
                  y: this.state.r_data.duration_win_probability["q2"].map(x => {
                    return x.win_probability;
                  }),
                  type: "scatter",
                  mode: "lines+points",
                  name: "Radiant",
                  line: {
                    color: "green"
                  }
                },
                {
                  x: this.state.d_data.duration_win_probability["q2"].map(x => {
                    return x.duration / 60;
                  }),
                  y: this.state.d_data.duration_win_probability["q2"].map(x => {
                    return x.win_probability;
                  }),
                  type: "scatter",
                  mode: "lines+points",
                  name: "Dire",
                  line: {
                    color: "red"
                  }
                }
              ]}
              style={{ width: "100%", height: "100%" }}
              layout={{
                autosize: true,
                xaxis: {
                  title: "Duration (in minutes)"
                },
                yaxis: {
                  range: [0, 1],
                  title: "Win Probability"
                }
              }}
              config={{ modeBarButtonsToRemove: ["sendDataToCloud"] }}
            />
          </div>
        ) : null}
      </div>
    );
  }
}

export default DraftMatch;
