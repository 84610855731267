import { ApiSetting } from "../resources/ApiSetting";
import AuthResource from "../resources/AuthResource";

let instance = null;

export default class AuthStorage {
  constructor() {
    if (!instance) {
      instance = this;
      this._supportStorage = Storage && localStorage;
      this._model = null;
    }
    return instance;
  }

  get supportStorage() {
    return this._supportStorage;
  }

  get isAuthenticated() {
    return this._model !== null && !this.isExpired;
  }

  get isExpired() {
    if (this._model !== null) {
      const expiration = new Date(this._model.expiration),
        now = new Date(),
        isExpired = now.getTime() >= expiration.getTime();
      if (isExpired) {
        this.logout();
      }
      return isExpired;
    }
    return true;
  }

  static key() {
    return "api_token";
  }

  refresh() {
    if (this.supportStorage && this._model === null) {
      let data = localStorage.getItem(AuthStorage.key());
      if (data !== undefined && data !== null) {
        this._model = JSON.parse(data);
        ApiSetting.API_KEY = this._model.token;
      }
    }
  }

  async login(credentials) {
    if (this.supportStorage) {
      try {
        let data = await AuthResource.login(credentials);
        localStorage.setItem(AuthStorage.key(), JSON.stringify(data));
        this._model = data;
        ApiSetting.API_KEY = this._model.token;
      } catch (err) {
        throw err;
      }
    }
  }

  logout() {
    if (this.supportStorage) {
      try {
        localStorage.removeItem(AuthStorage.key());
        this._model = null;
        ApiSetting.API_KEY = null;
      } catch (err) {
        throw err;
      }
    }
  }
}
