import React, { Component } from "react";
import Plot from "react-plotly.js";

class WinProbByDurationGraph extends Component {
  render() {
    const { duration_win_probability } = this.props;
    return (
      <Plot
        data={[
          {
            x: duration_win_probability["q2"].map(d => {
              return d.duration / 60;
            }),
            y: duration_win_probability["q2"].map(d => {
              return d.win_probability;
            }),
            type: "scatter",
            mode: "lines+points",
            name: "Median"
          },
          {
            x: duration_win_probability["q1"].map(d => {
              return d.duration / 60;
            }),
            y: duration_win_probability["q1"].map(d => {
              return d.win_probability;
            }),
            type: "scatter",
            mode: "lines+points",
            name: "Q1"
          },
          {
            x: duration_win_probability["q3"].map(d => {
              return d.duration / 60;
            }),
            y: duration_win_probability["q3"].map(d => {
              return d.win_probability;
            }),
            type: "scatter",
            mode: "lines+points",
            name: "Q3"
          }
        ]}
        style={{ width: "100%", height: "100%" }}
        layout={{
          autosize: true,
          xaxis: {
            title: "Duration (in minutes)"
          },
          yaxis: {
            range: [0, 1],
            title: "Win Probability"
          }
        }}
        config={{ modeBarButtonsToRemove: ["sendDataToCloud"] }}
      />
    );
  }
}

export default WinProbByDurationGraph;
