import ApiResource from "./ApiResource";

export default class DataResource extends ApiResource {
  static async getTimeseries(heroId, position) {
    const query_string = ApiResource._buildQueryString({
      hero_id: heroId,
      pos: position
    });
    return await ApiResource._request(
      "GET",
      `/data/timeseries?${query_string}`,
      null
    );
  }
}
