import React, { Component } from "react";
import ReactTable from "react-table";
import "react-table/react-table.css";
import { filterHeroCell, formatHeroCell } from "../utils/TableUtils";

const MATCHUP_COLUMNS = [
  {
    Header: "Hero Matchup",
    accessor: "id",
    Cell: formatHeroCell,
    filterable: true,
    filterMethod: filterHeroCell
  },
  {
    Header: "Ban Probability",
    accessor: "win_probability"
  }
];

class MatchUpTable extends Component {
  render() {
    const { matchups } = this.props;

    return <ReactTable data={matchups} columns={MATCHUP_COLUMNS} />;
  }
}

export default MatchUpTable;
