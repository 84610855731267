import React, { Component } from "react";
import Select from "react-select";
import hero_metadata from "../../assets/hero_metadata";
import DraftResource from "../../resources/DraftResource";
import { HERO_SELECT_OPTIONS } from "../../utils/HeroUtils";
import { formatOptionLabel } from "../../utils/SelectUtils";
import MatchUpTable from "../../components/MatchUpTable";

class DraftMatchup extends Component {
  constructor(props) {
    super(props);
    this.state = {
      h: [],
      matchups: null,
      isSubmitDisabled: true
    };
    this._onSelectH = this._onSelectH.bind(this);
    this._onSubmit = this._onSubmit.bind(this);
  }

  _onSelectH(options) {
    this.setState({
      h: options.map(o => {
        return o.value;
      }),
      isSubmitDisabled: !(options.length > 0)
    });
  }

  async _onSubmit() {
    this.setState({
      isSubmitDisabled: true
    });
    try {
      const data = await DraftResource.getDraftMatchup(this.state.h);
      const matchups = hero_metadata.map(hero => {
        return {
          id: hero["id"],
          win_probability: data.matchups[hero["id"]]
        };
      });
      this.setState({
        matchups: matchups
      });
    } catch (err) {
      // Do nothing
    }

    this.setState({
      isSubmitDisabled: false
    });
  }

  render() {
    return (
      <div className="container-fluid">
        <div className="pt-5 d-flex align-items-center">
          <div className="col-10">
            <Select
              options={HERO_SELECT_OPTIONS}
              formatOptionLabel={formatOptionLabel}
              placeholder={"Select heroes"}
              onChange={this._onSelectH}
              isMulti
              isSearchable
            />
          </div>

          <div className="col-2 text-center">
            <button
              className="btn btn-primary"
              type="submit"
              disabled={this.state.isSubmitDisabled}
              onClick={this._onSubmit}
            >
              Generate
            </button>
          </div>
        </div>

        {this.state.matchups !== null ? (
          <div className="pt-5">
            <MatchUpTable matchups={this.state.matchups} />
          </div>
        ) : null}
      </div>
    );
  }
}

export default DraftMatchup;
