import React, { Component } from "react";
import Select from "react-select";
import ReactTable from "react-table";
import "react-table/react-table.css";
import DraftResource from "../../resources/DraftResource";
import { HERO_SELECT_OPTIONS } from "../../utils/HeroUtils";
import { formatOptionLabel } from "../../utils/SelectUtils";
import { filterHeroCell, formatHeroCell } from "../../utils/TableUtils";

const COLUMNS = [
  {
    Header: "Hero",
    accessor: "h",
    Cell: formatHeroCell,
    filterable: true,
    filterMethod: filterHeroCell
  },
  {
    Header: "Win %",
    accessor: "win_probability"
  }
];

class DraftLastPick extends Component {
  constructor(props) {
    super(props);
    this.state = {
      d1: null,
      d2: null,
      d3: null,
      d4: null,
      d5: null,
      p1: null,
      p2: null,
      p3: null,
      p4: null,
      p5: null,
      bans: [],
      data: null,
      isSubmitDisabled: true
    };
    this._onSelectD1 = this._onSelectD1.bind(this);
    this._onSelectD2 = this._onSelectD2.bind(this);
    this._onSelectD3 = this._onSelectD3.bind(this);
    this._onSelectD4 = this._onSelectD4.bind(this);
    this._onSelectD5 = this._onSelectD5.bind(this);
    this._onSelectP1 = this._onSelectP1.bind(this);
    this._onSelectP2 = this._onSelectP2.bind(this);
    this._onSelectP3 = this._onSelectP3.bind(this);
    this._onSelectP4 = this._onSelectP4.bind(this);
    this._onSelectP5 = this._onSelectP5.bind(this);
    this._onSelectBans = this._onSelectBans.bind(this);
    this._onSubmit = this._onSubmit.bind(this);
  }

  static __isSubmitDisabled(d1, d2, d3, d4, d5, p1, p2, p3, p4, p5) {
    const isOppPicked = [p1, p2, p3, p4, p5].every(h => {
      return h !== null;
    });

    let totalHeroesPicked = 0;
    [d1, d2, d3, d4, d5].forEach(h => {
      if (h !== null) {
        totalHeroesPicked += 1;
      }
    });
    return !(isOppPicked && totalHeroesPicked === 4);
  }

  _onSelectD1(options) {
    const value =
      options !== undefined && options !== null ? options.value : null;
    this.setState({
      d1: value,
      isSubmitDisabled: DraftLastPick.__isSubmitDisabled(
        value,
        this.state.d2,
        this.state.d3,
        this.state.d4,
        this.state.d5,
        this.state.p1,
        this.state.p2,
        this.state.p3,
        this.state.p4,
        this.state.p5
      )
    });
  }

  _onSelectD2(options) {
    const value =
      options !== undefined && options !== null ? options.value : null;
    this.setState({
      d2: value,
      isSubmitDisabled: DraftLastPick.__isSubmitDisabled(
        this.state.d1,
        value,
        this.state.d3,
        this.state.d4,
        this.state.d5,
        this.state.p1,
        this.state.p2,
        this.state.p3,
        this.state.p4,
        this.state.p5
      )
    });
  }

  _onSelectD3(options) {
    const value =
      options !== undefined && options !== null ? options.value : null;
    this.setState({
      d3: value,
      isSubmitDisabled: DraftLastPick.__isSubmitDisabled(
        this.state.d1,
        this.state.d2,
        value,
        this.state.d4,
        this.state.d5,
        this.state.p1,
        this.state.p2,
        this.state.p3,
        this.state.p4,
        this.state.p5
      )
    });
  }

  _onSelectD4(options) {
    const value =
      options !== undefined && options !== null ? options.value : null;
    this.setState({
      d4: value,
      isSubmitDisabled: DraftLastPick.__isSubmitDisabled(
        this.state.d1,
        this.state.d2,
        this.state.d3,
        value,
        this.state.d5,
        this.state.p1,
        this.state.p2,
        this.state.p3,
        this.state.p4,
        this.state.p5
      )
    });
  }

  _onSelectD5(options) {
    const value =
      options !== undefined && options !== null ? options.value : null;
    this.setState({
      d5: value,
      isSubmitDisabled: DraftLastPick.__isSubmitDisabled(
        this.state.d1,
        this.state.d2,
        this.state.d3,
        this.state.d4,
        value,
        this.state.p1,
        this.state.p2,
        this.state.p3,
        this.state.p4,
        this.state.p5
      )
    });
  }

  _onSelectP1(options) {
    this.setState({
      p1: options.value,
      isSubmitDisabled: DraftLastPick.__isSubmitDisabled(
        this.state.d1,
        this.state.d2,
        this.state.d3,
        this.state.d4,
        this.state.d5,
        options.value,
        this.state.p2,
        this.state.p3,
        this.state.p4,
        this.state.p5
      )
    });
  }

  _onSelectP2(options) {
    this.setState({
      p2: options.value,
      isSubmitDisabled: DraftLastPick.__isSubmitDisabled(
        this.state.d1,
        this.state.d2,
        this.state.d3,
        this.state.d4,
        this.state.d5,
        this.state.p1,
        options.value,
        this.state.p3,
        this.state.p4,
        this.state.p5
      )
    });
  }

  _onSelectP3(options) {
    this.setState({
      p3: options.value,
      isSubmitDisabled: DraftLastPick.__isSubmitDisabled(
        this.state.d1,
        this.state.d2,
        this.state.d3,
        this.state.d4,
        this.state.d5,
        this.state.p1,
        this.state.p2,
        options.value,
        this.state.p4,
        this.state.p5
      )
    });
  }

  _onSelectP4(options) {
    this.setState({
      p4: options.value,
      isSubmitDisabled: DraftLastPick.__isSubmitDisabled(
        this.state.d1,
        this.state.d2,
        this.state.d3,
        this.state.d4,
        this.state.d5,
        this.state.p1,
        this.state.p2,
        this.state.p3,
        options.value,
        this.state.p5
      )
    });
  }

  _onSelectP5(options) {
    this.setState({
      p5: options.value,
      isSubmitDisabled: DraftLastPick.__isSubmitDisabled(
        this.state.d1,
        this.state.d2,
        this.state.d3,
        this.state.d4,
        this.state.d5,
        this.state.p1,
        this.state.p2,
        this.state.p3,
        this.state.p4,
        options.value
      )
    });
  }

  _onSelectBans(options) {
    const value = options.map(o => {
      return o.value;
    });
    this.setState({
      bans: value
    });
  }

  async _onSubmit() {
    this.setState({
      isSubmitDisabled: true
    });
    try {
      const data = await DraftResource.getDraftLastPick(
        this.state.p1,
        this.state.p2,
        this.state.p3,
        this.state.p4,
        this.state.p5,
        this.state.d1,
        this.state.d2,
        this.state.d3,
        this.state.d4,
        this.state.d5,
        this.state.bans
      );
      this.setState({
        data: data.rows
      });
    } catch (err) {
      // Do nothing
    }

    this.setState({
      isSubmitDisabled: false
    });
  }

  render() {
    return (
      <div className="container-fluid">
        <div className="pt-5 d-flex align-items-center">
          <div className="col-10">
            <Select
              options={HERO_SELECT_OPTIONS}
              defaultValue={this.state.bans}
              formatOptionLabel={formatOptionLabel}
              placeholder={"Select Bans"}
              onChange={this._onSelectBans}
              isMulti
              isSearchable
            />
          </div>
        </div>

        <div className="pt-5 d-flex align-items-center">
          <div className="col-5">
            <Select
              options={HERO_SELECT_OPTIONS}
              formatOptionLabel={formatOptionLabel}
              placeholder={"Select Carry"}
              onChange={this._onSelectD1}
              isSearchable
              isClearable
              escapeClearsValue
            />

            <Select
              options={HERO_SELECT_OPTIONS}
              formatOptionLabel={formatOptionLabel}
              placeholder={"Select Mid"}
              onChange={this._onSelectD2}
              isSearchable
              isClearable
              escapeClearsValue
            />

            <Select
              options={HERO_SELECT_OPTIONS}
              formatOptionLabel={formatOptionLabel}
              placeholder={"Select Offlane"}
              onChange={this._onSelectD3}
              isSearchable
              isClearable
              escapeClearsValue
            />

            <Select
              options={HERO_SELECT_OPTIONS}
              formatOptionLabel={formatOptionLabel}
              placeholder={"Select Support (4)"}
              onChange={this._onSelectD4}
              isSearchable
              isClearable
              escapeClearsValue
            />

            <Select
              options={HERO_SELECT_OPTIONS}
              formatOptionLabel={formatOptionLabel}
              placeholder={"Select Support (5)"}
              onChange={this._onSelectD5}
              isSearchable
              isClearable
              escapeClearsValue
            />
          </div>

          <div className="col-2 text-center">
            <button
              className="btn btn-primary"
              type="submit"
              disabled={this.state.isSubmitDisabled}
              onClick={this._onSubmit}
            >
              Generate
            </button>
          </div>

          <div className="col-5">
            <Select
              options={HERO_SELECT_OPTIONS}
              formatOptionLabel={formatOptionLabel}
              placeholder={"Select Opp Carry"}
              onChange={this._onSelectP1}
              isSearchable
            />

            <Select
              options={HERO_SELECT_OPTIONS}
              formatOptionLabel={formatOptionLabel}
              placeholder={"Select Opp Mid"}
              onChange={this._onSelectP2}
              isSearchable
            />

            <Select
              options={HERO_SELECT_OPTIONS}
              formatOptionLabel={formatOptionLabel}
              placeholder={"Select Opp Offlane"}
              onChange={this._onSelectP3}
              isSearchable
            />

            <Select
              options={HERO_SELECT_OPTIONS}
              formatOptionLabel={formatOptionLabel}
              placeholder={"Select Opp Support (4)"}
              onChange={this._onSelectP4}
              isSearchable
            />

            <Select
              options={HERO_SELECT_OPTIONS}
              formatOptionLabel={formatOptionLabel}
              placeholder={"Select Opp Support (5)"}
              onChange={this._onSelectP5}
              isSearchable
            />
          </div>
        </div>

        {this.state.data !== null ? (
          <div className="pt-5">
            <ReactTable data={this.state.data} columns={COLUMNS} />
          </div>
        ) : null}
      </div>
    );
  }
}

export default DraftLastPick;
