import { HERO_ID_TO_PROPS } from "./HeroUtils";
import React from "react";

const formatHeroCell = row => {
  return (
    <span>
      <img
        style={{ height: "1em", width: "1.78em" }}
        src={HERO_ID_TO_PROPS[row.value].icon}
        alt={HERO_ID_TO_PROPS[row.value].name}
      />
      {HERO_ID_TO_PROPS[row.value].name}
    </span>
  );
};

const formatPivotHeroCell = row => {
  if (!Array.isArray(row.value)) {
    return formatHeroCell(row);
  }
  return (
    <ul className="list-unstyled list-inline">
      {row.value.map(h => {
        return (
          <li key={h} className="list-inline-item">
            <img
              style={{ height: "1em", width: "1.78em" }}
              src={HERO_ID_TO_PROPS[h].icon}
              alt={HERO_ID_TO_PROPS[h].name}
            />
          </li>
        );
      })}
    </ul>
  );
};

const filterHeroCell = (filter, row) => {
  const hero_name = HERO_ID_TO_PROPS[row[filter.id]].name;
  return hero_name.startsWith(filter.value) && hero_name.endsWith(filter.value);
};

export { formatHeroCell, formatPivotHeroCell, filterHeroCell };
