import React from "react";

const formatOptionLabel = ({ icon, label }) => {
  return (
    <div style={{ alignItems: "center", display: "flex" }}>
      <span style={{ fontSize: 14, marginRight: "0.5em" }}>
        <img
          style={{ height: "1em", width: "1.78em" }}
          src={icon}
          alt={label}
        />
      </span>
      <span style={{ fontSize: 14 }}>{label}</span>
    </div>
  );
};

export { formatOptionLabel };
