import React, { Component } from "react";
import Select from "react-select";
import DataResource from "../../resources/DataResource";
import {
  HERO_SELECT_OPTIONS,
  POSITION_SELECT_OPTIONS
} from "../../utils/HeroUtils";
import { formatOptionLabel } from "../../utils/SelectUtils";
import { Tab, TabList, TabPanel, Tabs } from "react-tabs";
import "react-tabs/style/react-tabs.css";
import TimeseriesGraph from "../../components/TimeseriesGraph";

class Timeseries extends Component {
  constructor(props) {
    super(props);
    this.state = {
      h1: null,
      p1: [],
      isSubmitDisabled: true,
      timeseries: null
    };
    this._onSelectH1 = this._onSelectH1.bind(this);
    this._onSelectP1 = this._onSelectP1.bind(this);
    this._onSubmit = this._onSubmit.bind(this);
  }

  _onSelectH1(options) {
    this.setState({
      h1: options.value,
      isSubmitDisabled: !(options.value !== null && this.state.p1.length > 0)
    });
  }

  _onSelectP1(options) {
    this.setState({
      p1: options.map(o => {
        return o.value;
      }),
      isSubmitDisabled: !(options.length > 0 && this.state.h1 !== null)
    });
  }

  async _onSubmit() {
    this.setState({
      isSubmitDisabled: true
    });
    try {
      const timeseries = await Promise.all(
        this.state.p1.map(async p => {
          const t = await DataResource.getTimeseries(this.state.h1, p);
          return {
            p: p,
            t: t
          };
        })
      );
      this.setState({
        timeseries: timeseries
      });
    } catch (err) {
      // Do nothing
    }

    this.setState({
      isSubmitDisabled: false
    });
  }

  render() {
    return (
      <div className="container-fluid">
        <div className="pt-5 d-flex align-items-center">
          <div className="col-10">
            <Select
              options={HERO_SELECT_OPTIONS}
              formatOptionLabel={formatOptionLabel}
              placeholder={"Select Hero"}
              onChange={this._onSelectH1}
              isSearchable
            />

            <Select
              options={POSITION_SELECT_OPTIONS}
              placeholder={"Select Position"}
              onChange={this._onSelectP1}
              isMulti
            />
          </div>

          <div className="col-2 text-center">
            <button
              className="btn btn-primary"
              type="submit"
              disabled={this.state.isSubmitDisabled}
              onClick={this._onSubmit}
            >
              Generate
            </button>
          </div>
        </div>

        {this.state.timeseries !== undefined &&
        this.state.timeseries !== null ? (
          <div className="pt-5">
            <Tabs>
              <TabList>
                <Tab>GPM</Tab>
                <Tab>XPM</Tab>
                <Tab>Net Worth</Tab>
                <Tab>Kills</Tab>
                <Tab>Deaths</Tab>
                <Tab>Assists</Tab>
              </TabList>

              <TabPanel>
                <TimeseriesGraph
                  timeseries={this.state.timeseries}
                  property_key={"gpm"}
                  range={[0, 1500]}
                />
              </TabPanel>

              <TabPanel>
                <TimeseriesGraph
                  timeseries={this.state.timeseries}
                  property_key={"xpm"}
                  range={[0, 1000]}
                />
              </TabPanel>

              <TabPanel>
                <TimeseriesGraph
                  timeseries={this.state.timeseries}
                  property_key={"net_worth"}
                  range={[0, 20000]}
                />
              </TabPanel>

              <TabPanel>
                <TimeseriesGraph
                  timeseries={this.state.timeseries}
                  property_key={"kills"}
                  range={[0, 20]}
                />
              </TabPanel>

              <TabPanel>
                <TimeseriesGraph
                  timeseries={this.state.timeseries}
                  property_key={"deaths"}
                  range={[0, 20]}
                />
              </TabPanel>

              <TabPanel>
                <TimeseriesGraph
                  timeseries={this.state.timeseries}
                  property_key={"assists"}
                  range={[0, 20]}
                />
              </TabPanel>
            </Tabs>
          </div>
        ) : null}
      </div>
    );
  }
}

export default Timeseries;
