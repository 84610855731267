import React, { Component } from "react";
import AuthStorage from "../../storage/AuthStorage";

const AUTH_SESSION = new AuthStorage();

export default class LoginForm extends Component {
  constructor(props) {
    super(props);
    this.state = { email: "", password: "", isSubmitting: false };
    this._handleChange = this._handleChange.bind(this);
    this._handleSubmit = this._handleSubmit.bind(this);
  }

  _handleChange(event) {
    event.target.classList.add("active");
    this.setState({
      [event.target.name]: event.target.value
    });
    this.showInputError(event.target.name);
  }

  async _handleSubmit(event) {
    event.preventDefault();

    if (this.showFormErrors() && !this.state.isSubmitting) {
      let credentials = {
        email: this.state.email,
        password: this.state.password
      };
      this.setState({ isSubmitting: true });
      try {
        await AUTH_SESSION.login(credentials);
        let nextPathname = "/";

        if (
          this.props.location.state !== undefined &&
          this.props.location.state !== null &&
          this.props.location.state.from !== undefined &&
          this.props.location.state.from !== null &&
          this.props.location.state.from.pathname !== undefined &&
          this.props.location.state.from.pathname !== null
        ) {
          nextPathname = this.props.location.state.from.pathname;
        }
        this.props.history.push(nextPathname);
      } catch (err) {
        this.setState({ isSubmitting: false });
        this.props.onError(err);
      }
    }
  }

  isFormValid() {
    const inputs = document.querySelectorAll("input:not([readonly])");
    let isFormValid = true;

    inputs.forEach(input => {
      const isInputError = this.isInputError(input.name);
      if (isInputError) {
        isFormValid = false;
      }
    });

    return isFormValid;
  }

  isInputError(refName) {
    if (this.refs[refName] !== undefined) {
      const validity = this.refs[refName].validity;
      return !validity.valid;
    }
    return true;
  }

  showFormErrors() {
    const inputs = document.querySelectorAll("input:not([readonly])");
    let isFormValid = true;

    inputs.forEach(input => {
      input.classList.add("active");

      const isInputValid = this.showInputError(input.name);

      if (!isInputValid) {
        isFormValid = false;
      }
    });

    return isFormValid;
  }

  showInputError(refName) {
    if (this.refs[refName] !== undefined) {
      const validity = this.refs[refName].validity,
        label = document.getElementById(`${refName}Label`).textContent,
        error = document.getElementById(`${refName}Error`);

      if (!validity.valid) {
        if (validity.valueMissing) {
          error.textContent = `${label} is a required field`;
        } else if (validity.typeMismatch) {
          error.textContent = `${label} should be a valid email address`;
        }
        return false;
      }

      error.textContent = "";
      return true;
    }
    return false;
  }

  render() {
    let isButtonDisabled = this.state.isSubmitting || !this.isFormValid();

    return (
      <form noValidate={true}>
        <div className="form-row">
          <div className="form-group col-12">
            <label id="emailLabel">E-mail Address</label>
            <input
              className="form-control"
              type="email"
              name="email"
              ref="email"
              required={true}
              value={this.state.email}
              onChange={this._handleChange}
            />
            <small id="emailError" className="form-text text-danger" />
          </div>
        </div>

        <div className="form-row">
          <div className="form-group col-12">
            <label id="passwordLabel">Password</label>
            <input
              className="form-control"
              type="password"
              name="password"
              ref="password"
              required={true}
              value={this.state.password}
              onChange={this._handleChange}
            />
            <small id="passwordError" className="form-text text-danger" />
          </div>
        </div>

        <div className="form-row justify-content-around align-items-center">
          <div className="col-6 text-center">
            <button
              className="btn btn-secondary text-uppercase"
              type="submit"
              disabled={isButtonDisabled}
              onClick={this._handleSubmit}
            >
              Log In
            </button>
          </div>
        </div>
      </form>
    );
  }
}
